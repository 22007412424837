import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  guarantee: {},
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: 'createGuarantee',
  initialState,
  reducers: {
    // START LOADING
    startGetGuaranteeLoading(state) {
      state.isLoading = true
    },

    stopGetGuaranteeLoading(state) {
      state.isLoading = false
    },
    // HAS ERROR
    hasGetGuaranteeError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Guarantee
    getGuaranteeRequestSuccess(state, action) {
      state.isLoading = false
      state.guarantee = action.payload
      state.error = null
    }
  }
})

export const {
  getGuaranteeRequestSuccess,
  hasGetGuaranteeError,
  startGetGuaranteeLoading,
  stopGetGuaranteeLoading
} = slice.actions

// Reducer
export default slice.reducer
