import { Box, Button, Grid, TextField } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { AmountAndCurrency } from '../AmountAndCurrency'
import { EntityDetailsRow } from '../EntityRow'
import { ExpiryDate, Orgs, TimeRow } from '../section'
import { RootState, useSelector } from '../../../../redux/store'
import { TermsAndConditions, TermsAndConditionsSelection } from '../autocomplete/DropDowns'
import { overrideCurrencyStyle } from 'components/request-action/styles/styles'
import { useContext, useRef, useState } from 'react'
import { GlobalInfoContext, initialState } from 'contexts/GlobalStateContext'
import { useNavigate } from 'react-router-dom'
import { Dayjs } from 'dayjs'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { PATH_DASHBOARD } from 'routes/paths'
import useSocketConnection from 'hooks/useSocketConnection'
import { downloadAsPDF, sendDocumentRequest } from 'pages/dashboard/utils'
import { Heading, SectionBreak, ElementBreak, CreateGxInitialValues } from './shared'
import { convertAmount } from './utils'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    paddingBottom: '2rem',
    justifyContent: 'space-between',
    textAlign: 'right',
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 20
  }
})

export type InitialValues = CreateGxInitialValues & {
  terms: string
  name: string
  startDate: string
  endDate: string
}

const PaymentPerformanceGuarantee = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [, setApplicant] = useState<Orgs>({
    label: '',
    businessId: '',
    country: '',
    displayId: '',
    type: ''
  })
  const [, setBene] = useState<Orgs>({
    label: '',
    businessId: '',
    country: '',
    displayId: '',
    type: ''
  })
  const [, setIssuer] = useState<Orgs>({
    label: '',
    businessId: '',
    country: '',
    displayId: '',
    type: ''
  })

  const [, setCurrency] = useState('AUD')

  const [startDateValue, setstartDateValue] = useState<Dayjs | null>(null)
  const [endDateValue, setendDateValue] = useState<Dayjs | null>(null)
  const [contractExpiryValue, setContractExpiryValue] = useState<Dayjs | null>(null)
  const [isTermsSelected, setIsTermsSelected] = useState(false)
  const [isTermsFetched, setIsTermsFetched] = useState(false)
  const [, setTerms] = useState<TermsAndConditions>({
    termsDisplay: '',
    deedDisplay: '',
    termsIdentifier: '',
    deedIdentifier: ''
  })

  const {
    initiatedBy,
    setReqInfo,
    purpose,
    applicants,
    beneficiary,
    issuers,
    reference,
    amount: contextAmount,
    terms
  } = useContext(GlobalInfoContext)

  const [amountSet, setAmount] = useState<number | null>(
    contextAmount.nanos > 0 ? parseFloat(`${contextAmount?.units}.${contextAmount?.nanos}`) : null
  )

  const { onSocketSend } = useSocketConnection()
  const [, setStartYear] = useState('')
  const [, setStartMonth] = useState('')
  const [, setStartDay] = useState('')
  const [, setContractExpiryYear] = useState('')
  const [, setContractExpiryMonth] = useState('')
  const [, setContractExpiryDay] = useState('')
  const { businessUnitActionContexts: buacs } = useSelector(
    (state: RootState) => state.createGuaranteePayload
  )
  const GuaranteeCreateSchema = Yup.object().shape({
    applicant: Yup.string().required('Applicant Name is required'),
    bene: Yup.string().required('Beneficiary Name is required'),
    issuer: Yup.string().when({
      is: () => initiatedBy === 'beneficiary',
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required('Issuer Name is required')
    }),
    purposeDescription: Yup.string().required('Purpose of the gurantee is required'),
    amount: Yup.number().min(1).required('Amount cannot be empty'),
    number: Yup.string().notRequired(),
    name: Yup.string().notRequired(),
    comments: Yup.string().notRequired(),
    startDate: Yup.date().notRequired(),
    endDate: Yup.date().notRequired(),
    terms: Yup.object().required('Please select terms'),
    expiryDate: Yup.date().when({
      is: () => purpose?.isOpenEnded,
      then: Yup.date().notRequired(),
      otherwise: Yup.date().required('Expiry Date is required')
    }),
    reference: Yup.string().notRequired()
  })

  const [, setendDateYear] = useState('')
  const [, setendDateMonth] = useState('')
  const [, setendDateDay] = useState('')

  const formik = useFormik<InitialValues>({
    initialValues: {
      applicant: applicants[0]?.label || '',
      bene: beneficiary[0]?.label || '',
      issuer: issuers[0]?.label || '',
      terms: terms?.termsDisplay || '',
      purposeDescription: purpose?.purposeDescription || '',
      number: purpose?.contract?.number || '',
      name: purpose?.contract?.name || '',
      comments: purpose?.contract?.comments || '',
      expiryDate: purpose?.expiryDate || '',
      startDate: purpose?.contract?.startDate || '',
      endDate: purpose?.contract?.endDate || '',
      amount: contextAmount?.units || 0,
      isOpenEnded: purpose?.isOpenEnded || false,
      reference: reference || ''
    },
    validationSchema: GuaranteeCreateSchema,
    onSubmit: (values) => {
      setReqInfo({
        reference: values?.reference,
        terms: values?.terms,
        amount: convertAmount(amountSet!),
        purpose: {
          ...purpose,
          expiryDate: values?.expiryDate,
          isOpenEnded: values?.isOpenEnded,
          purposeDescription: values?.purposeDescription,
          contract: {
            ...purpose?.contract,
            name: values?.name,
            number: values?.number,
            comments: values?.comments
          }
        }
      })
      navigate(PATH_DASHBOARD.general.guaranteeCreate.review)
    }
  })
  const { errors, touched, getFieldProps } = formik

  const handleAmount = (value: number) => {
    formik.setFieldValue('amount', value, true)
    setAmount(value)
  }

  const childRef = useRef()
  const childRefForTerms = useRef()
  const issuerRef = useRef()
  const handleDate = (value: Dayjs, type: string) => {
    const formattedValue = value?.format('YYYY/MM/DD')
    if (type === 'expiryDate') {
      formik.setFieldValue('expiryDate', formattedValue, true)
      setContractExpiryValue(value)
      setContractExpiryYear(formattedValue?.split('/')[0])
      setContractExpiryMonth(formattedValue?.split('/')[1])
      setContractExpiryDay(formattedValue?.split('/')[2])
    } else if (type === 'startDate') {
      formik.setFieldValue('startDate', formattedValue, true)

      setstartDateValue(value)
      setStartYear(formattedValue?.split('/')[0])
      setStartMonth(formattedValue?.split('/')[1])
      setStartDay(formattedValue?.split('/')[2])
    } else if (type === 'endDate') {
      formik.setFieldValue('endDate', formattedValue, true)

      setendDateValue(value)
      setendDateYear(formattedValue?.split('/')[0])
      setendDateMonth(formattedValue?.split('/')[1])
      setendDateDay(formattedValue?.split('/')[2])
    }
    if (type === 'expiryDate') {
      setReqInfo({
        purpose: {
          ...purpose,
          expiryDate: formattedValue,
          contract: {
            ...purpose?.contract
          }
        }
      })
    } else {
      setReqInfo({
        purpose: {
          ...purpose,
          contract: {
            ...purpose?.contract,
            [`${type}`]: formattedValue
          }
        }
      })
    }
  }

  const handleOpenEndedDate = (value) => {
    setReqInfo({
      purpose: {
        ...purpose,
        isOpenEnded: !purpose?.isOpenEnded,
        expiryDate: '',
        contract: {
          ...purpose?.contract
        }
      }
    })

    setContractExpiryValue(null)
    formik.setFieldValue('isOpenEnded', value, true)
  }

  const handleEntityValue = (value: Orgs, type: string) => {
    if (type === 'applicant') {
      formik.setFieldValue('applicant', value?.label, true)

      setApplicant(value)
      setReqInfo({
        applicants: [value]
      })
    } else if (type === 'beneficiary') {
      formik.setFieldValue('bene', value?.label, true)

      setBene(value)
      setReqInfo({
        beneficiary: [value]
      })
    } else if (type === 'issuer') {
      formik.setFieldValue('issuer', value?.label, true)

      setIssuer(value)
      setReqInfo({
        issuers: [value]
      })
    }
  }

  const handleTerms = (value: TermsAndConditions) => {
    formik.setFieldValue('terms', value, true)

    setTerms(value)
    setReqInfo({
      terms: value
    })
  }
  const handleClear = (type: string) => {
    if (type === 'applicant') {
      formik.setFieldValue('applicant', '', true)
      setReqInfo({
        applicants: []
      })
    } else if (type === 'bene') {
      formik.setFieldValue('bene', '', true)
      setReqInfo({
        beneficiary: []
      })
    } else if (type === 'issuer') {
      formik.setFieldValue('issuer', '', true)

      setReqInfo({
        issuers: []
      })
    }
  }

  const exitCreationProcess = () => {
    navigate(PATH_DASHBOARD.general.app)
    setReqInfo(initialState)
  }

  const downloadDocs = (documentIdentifier: string, title: string) => {
    buacs[0]?.businessUnitActionContext[0]?.workflowRole?.roleIdentifier &&
      sendDocumentRequest(
        buacs[0]?.businessUnitActionContext[0]?.workflowRole?.roleIdentifier,
        documentIdentifier,
        onSocketSend
      ).then(() => {
        downloadAsPDF(
          documentIdentifier,
          buacs[0]?.businessUnitActionContext[0]?.workflowRole?.roleIdentifier,
          title
        )
      })
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <SectionBreak>
          <Grid container sx={{ marginBottom: '20px' }}>
            <Heading>Parties</Heading>
          </Grid>
          {initiatedBy === 'applicant' ? (
            <div>
              <ElementBreak>
                <EntityDetailsRow
                  label="Applicant Name"
                  type="applicant"
                  touched={touched}
                  key={`applicant-${purpose?.type}`}
                  errors={errors}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'applicant')}
                  initialOption={applicants[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={buacs}
                  resetTermsRef={childRefForTerms}
                  ref={childRef}
                  resetIssuer={issuerRef}
                  setContractFetch={setIsTermsFetched}
                  isTermsFetched={isTermsFetched}
                  clearable={false}
                />
              </ElementBreak>
              <ElementBreak>
                <EntityDetailsRow
                  label="Beneficiary Name"
                  type="bene"
                  touched={touched}
                  errors={errors}
                  key={`bene-${purpose?.type}`}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'beneficiary')}
                  initialOption={beneficiary[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={[]}
                  resetTermsRef={childRefForTerms}
                  ref={childRef}
                  resetIssuer={issuerRef}
                  setContractFetch={setIsTermsFetched}
                  isTermsFetched={isTermsFetched}
                />
              </ElementBreak>
            </div>
          ) : (
            <div>
              <ElementBreak>
                <EntityDetailsRow
                  label="Beneficiary Name"
                  type="bene"
                  touched={touched}
                  errors={errors}
                  key={`bene-${purpose?.type}`}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'beneficiary')}
                  initialOption={beneficiary[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={buacs}
                  resetTermsRef={childRefForTerms}
                  setContractFetch={setIsTermsFetched}
                  isTermsFetched={isTermsFetched}
                  clearable={false}
                />
              </ElementBreak>
              <ElementBreak>
                <EntityDetailsRow
                  label="Applicant Name"
                  type="applicant"
                  touched={touched}
                  errors={errors}
                  key={`applicant-${purpose?.type}`}
                  getFieldProps={getFieldProps}
                  setValue={(value) => handleEntityValue(value, 'applicant')}
                  initialOption={applicants[0]}
                  handleClear={handleClear}
                  initiatedBy={initiatedBy}
                  initialValues={[]}
                  resetTermsRef={childRefForTerms}
                  ref={childRef}
                  setContractFetch={setIsTermsFetched}
                  isTermsFetched={isTermsFetched}
                />
              </ElementBreak>
            </div>
          )}

          {initiatedBy === 'applicant' && (
            <ElementBreak>
              <EntityDetailsRow
                label="Issuer Name"
                type="issuer"
                touched={touched}
                errors={errors}
                getFieldProps={getFieldProps}
                key={`bene-${purpose?.type}`}
                setValue={(value) => handleEntityValue(value, 'issuer')}
                initialOption={issuers[0]}
                handleClear={handleClear}
                initialValues={buacs}
                initiatedBy={initiatedBy}
                resetTermsRef={childRefForTerms}
                issuerRef={issuerRef}
                ref={childRef}
                setContractFetch={setIsTermsFetched}
                isTermsFetched={isTermsFetched}
                clearable={false}
              />
            </ElementBreak>
          )}
        </SectionBreak>
        <SectionBreak>
          <Grid container sx={{ marginBottom: '20px' }}>
            <Heading>Guarantee Purpose</Heading>
          </Grid>
          <ElementBreak>
            <Grid item md={4} lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Contract Number"
                placeholder="Enter Contract number"
                id="number"
                name="number"
                onChange={formik.handleChange('number')}
                value={formik.values.number}
                error={Boolean(touched.number && errors.number)}
                helperText={touched.number && errors.number}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>
            <Grid item md={1} lg={1} />

            <Grid item md={4} lg={4}>
              <TextField
                InputLabelProps={{ shrink: true }}
                label="Contract Name"
                name="name"
                id="name"
                placeholder="Enter Contract name"
                value={formik?.values.name}
                onChange={formik?.handleChange('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>
          </ElementBreak>
          <Box>
            <ElementBreak>
              <Grid container item spacing={2}>
                <TimeRow
                  startDateValue={startDateValue}
                  endDateValue={endDateValue}
                  setDateValue={handleDate}
                  openEnded={formik.values.isOpenEnded}
                  setOpenEnded={handleOpenEndedDate}
                  errors={errors}
                  touched={touched}
                />
              </Grid>
            </ElementBreak>
          </Box>
          <ElementBreak>
            <Grid item md={9} lg={9}>
              <TextField
                id="purpose-of-the-guarantee"
                multiline
                rows={10}
                placeholder="The detailed description of the contract."
                label="Purpose of the Guarantee"
                value={formik.values.purposeDescription}
                onChange={formik?.handleChange('purposeDescription')}
                size="medium"
                style={{ width: '100%' }}
                InputLabelProps={{ shrink: true }}
                error={Boolean(touched.purposeDescription && errors.purposeDescription)}
                helperText={touched.purposeDescription && errors.purposeDescription}
                inputProps={{ maxLength: 250 }}
              />
            </Grid>
          </ElementBreak>
          <Box sx={{ flexGrow: 1, ...classes }}>
            <Grid container item spacing={2}>
              <Grid item md={9} lg={9}>
                <TextField
                  id="description"
                  multiline
                  rows={10}
                  placeholder="Provide any additional comments here."
                  label="Optional Comments"
                  value={formik.values.comments}
                  onChange={formik?.handleChange('comments')}
                  size="medium"
                  style={{ width: '100%' }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ maxLength: 1000 }}
                  error={Boolean(touched.comments && errors.comments)}
                  helperText={touched.comments && errors.comments}
                />
              </Grid>
            </Grid>
          </Box>
        </SectionBreak>
        <SectionBreak>
          <ElementBreak>
            <Heading>Guarantee Details</Heading>
          </ElementBreak>
          <ElementBreak>
            <ExpiryDate
              expiryDateValue={contractExpiryValue}
              setExpiryDate={handleDate}
              openEnded={formik.values.isOpenEnded}
              handleOpenEndedDate={handleOpenEndedDate}
              errors={errors}
              touched={touched}
            />
          </ElementBreak>
          <ElementBreak>
            <AmountAndCurrency
              setCurrency={setCurrency}
              amount={amountSet}
              setAmount={handleAmount}
              touched={touched}
              errors={errors}
            />
          </ElementBreak>

          <Box>
            <Grid container item spacing={2}>
              <Grid item md={4} lg={4}>
                <TermsAndConditionsSelection
                  handleIssuerClear={childRef}
                  handleClear={handleClear}
                  setIsTermsSelected={setIsTermsSelected}
                  handleEntityValue={handleTerms}
                  ref={childRefForTerms}
                  errors={errors}
                  touched={touched}
                />
              </Grid>
              <Grid item md={1} lg={1} />
              <Grid item md={2} lg={2}>
                {isTermsSelected && (
                  <a href="#" onClick={() => downloadDocs(terms?.termsIdentifier, 'terms')}>
                    Preview Terms
                  </a>
                )}
              </Grid>
            </Grid>
          </Box>
        </SectionBreak>
        <SectionBreak>
          <Grid item md={4} lg={4}>
            <ElementBreak>
              <Heading>Internal Reference Number</Heading>
            </ElementBreak>
            <TextField
              label="IRN (Optional)"
              InputLabelProps={{ shrink: true }}
              value={formik?.values?.reference}
              onChange={formik?.handleChange('reference')}
              inputProps={{ maxLength: 50 }}
            />
          </Grid>
        </SectionBreak>
        <Box>
          <Grid container spacing={3}>
            <Grid container item spacing={2} />
            <Grid container item spacing={2}>
              <Grid item md={3}>
                <Button
                  variant="outlined"
                  sx={{ ...overrideCurrencyStyle }}
                  onClick={exitCreationProcess}
                >
                  Exit
                </Button>
              </Grid>
              <Grid item md={5}>
                <Button
                  variant="contained"
                  type="submit"
                  data-testid="review-and-submit-btn"
                  id="review-and-submit-btn"
                >
                  Review And Submit
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </div>
  )
}

export default PaymentPerformanceGuarantee
