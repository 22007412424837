import { Helmet } from 'react-helmet-async'
import { forwardRef, useEffect, useCallback, ReactNode } from 'react'
// material
import { Box, BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

interface PageProps extends BoxProps {
  children: ReactNode
  title?: string
}

const Page = forwardRef<HTMLDivElement, PageProps>(({ children, title = '', ...other }, ref) => {
  const sendPageViewEvent = useCallback(() => {
    // Nothing to do for tracking
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    sendPageViewEvent()
  }, [sendPageViewEvent])
  {
    /* @ts-ignore */
  }

  return (
    <Box ref={ref} {...other}>
      {/* @ts-ignore */}
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  )
})

export default Page
