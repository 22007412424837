// material
import { Button, Grid, CircularProgress } from '@mui/material'
import TableBody from '@mui/material/TableBody'
import Paper from '@mui/material/Paper'
// redux
import { RootState, useDispatch, useSelector } from '../../redux/store'
import { Notification } from '../../@types/notification'
import NoNotifications from 'pages/NoNotifications'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import { fDateWithTimeZone } from 'utils/formatTime'
import Page from 'components/Page'
import { generateID } from 'utils/generateMessageID'
import useSocketConnection from 'hooks/useSocketConnection'
import { Request } from 'schemas/request'
import { getAllNotificationsSuccess } from 'redux/slices/notifications'
import TaskCounter from './TaskCounter'
import { CenteredContainer, StyledTableCell, StyledTableRow } from './styles/Notifications.styles'
import HeadingTitle from './HeadingTitle'

// ----------------------------------------------------------------------

export default function Notifications() {
  const dispatch = useDispatch()

  const { onSocketSend } = useSocketConnection()
  const { notifications, isLoading } = useSelector((state: RootState) => state?.notifications)

  const handleNotification = (id) => {
    const notificationAck: Request = {
      messageId: generateID(),
      messageType: 'AcknowledgeNotifications',
      AcknowledgeNotifications: { acknowledged: true, businessMessageIds: [id] }
    }
    onSocketSend(notificationAck)
    const filteredNotifications = notifications?.filter((no) => no.businessMessageId !== id)
    dispatch(getAllNotificationsSuccess(filteredNotifications))
  }

  const ackAllNotifications = () => {
    const ids: string[] = []
    notifications.forEach((element: Notification) => {
      ids.push(element?.businessMessageId)
    })
    const notificationsAck: Request = {
      messageId: generateID(),
      messageType: 'AcknowledgeNotifications',
      AcknowledgeNotifications: { acknowledged: true, businessMessageIds: ids }
    }
    onSocketSend(notificationsAck)
    dispatch(getAllNotificationsSuccess([]))
  }

  return (
    <>
      <Page title="Notifications" sx={{ margin: '0 24px 0 18px' }}>
        <Grid item sx={{ marginBottom: '18px' }}>
          <HeadingTitle>Notifications</HeadingTitle>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: '18px', height: '100%' }}
        >
          <TaskCounter type="notification" rows={notifications} />
          {!isLoading && notifications?.length > 0 && (
            <Button
              variant="outlined"
              size="large"
              onClick={() => ackAllNotifications()}
              key="dismiss-all-btn"
              data-testid="dismiss-all-btn"
              sx={{ color: '#1890FF' }}
            >
              DISMISS ALL
            </Button>
          )}
        </Grid>

        {isLoading && (
          <CenteredContainer>
            <CircularProgress />
          </CenteredContainer>
        )}
        {!isLoading && (
          <TableContainer component={Paper} title="Notifications">
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableBody>
                {notifications?.map((row: Notification) => (
                  <StyledTableRow key={row?.businessMessageId}>
                    <StyledTableCell component="th" scope="row" sx={{ width: '80%' }}>
                      <p>{row?.notificationDisplay}</p>
                    </StyledTableCell>
                    <StyledTableCell sx={{ whiteSpace: 'nowrap' }} align="right">
                      {fDateWithTimeZone(row?.happenedAt.toString())}
                    </StyledTableCell>
                    <StyledTableCell>
                      <Button
                        sx={{ color: '#1890FF' }}
                        onClick={() => handleNotification(row.businessMessageId)}
                      >
                        DISMISS
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!isLoading && notifications?.length === 0 && (
          <NoNotifications title="No Notifications" type="notifications" />
        )}
      </Page>
    </>
  )
}
