// material
import { CircularProgress, Box } from '@mui/material'
// redux
import NoNotifications from 'pages/NoNotifications'
import { Guarantee } from '../../@types/guarantee'
import { useNavigate } from 'react-router'
import { PATH_DASHBOARD } from 'routes/paths'
import Page from 'components/Page'
import { Table, TableContainer } from './styles/MyTasks.styles'
import TaskCounter from './TaskCounter'
import HeadingTitle from './HeadingTitle'
import { getTaskId, useMyTasks } from 'hooks/useMyTasks'
import { fDateWithTimeZone } from 'utils/formatTime'
import { displayValue } from 'components/request-action/sections/forms/utils'

// ----------------------------------------------------------------------

export default function MyTasks() {
  const navigate = useNavigate()
  const { myTasks, isLoading } = useMyTasks()
  return (
    <>
      <Page title="My Tasks" sx={{ margin: '0 16px' }}>
        {isLoading && (
          <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>
        )}
        <Box sx={{ marginBottom: '18px' }}>
          <HeadingTitle>My Tasks</HeadingTitle>
        </Box>
        <Box sx={{ marginBottom: '24px' }}>
          <TaskCounter type="my-task" rows={myTasks} />
        </Box>
        {myTasks && myTasks.length > 0 && (
          <TableContainer>
            <Table>
              <thead>
                <tr>
                  <th>Request Type</th>
                  <th>Guarantee ID</th>
                  <th>Requested By</th>
                  <th>Currency</th>
                  <th>Value</th>
                  <th>Request Date</th>
                </tr>
              </thead>
              <tbody>
                {myTasks.length > 0 &&
                  myTasks?.map((row: Guarantee) => (
                    <tr
                      key={getTaskId(row)}
                      onClick={() =>
                        navigate(`${PATH_DASHBOARD.general.guarantee}/${getTaskId(row)}`)
                      }
                    >
                      <td>{getRequestType(row)}</td>
                      <td>{getTaskId(row)}</td>
                      <td>{row?.TripartiteContractState?.applicants[0]?.commonName}</td>
                      <td>{row?.TripartiteContractState?.amount?.currencyCode}</td>
                      <td>{displayValue(row?.TripartiteContractState?.amount)}</td>
                      <td>
                        {row?.TripartiteContractState?.eventTimestamp.includes('UTC' || 'utc')
                          ? fDateWithTimeZone(row?.TripartiteContractState?.eventTimestamp)
                          : row?.TripartiteContractState?.eventTimestamp}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </TableContainer>
        )}
        {!isLoading && myTasks?.length === 0 && <NoNotifications title="No Tasks" type="tasks" />}
      </Page>
    </>
  )
}

const getRequestType = (guarantee: Guarantee) => {
  let requestType
  guarantee.businessUnitActionContext[0]?.availableActions.forEach((av: any) => {
    if (
      (av.roleAction !== 'EXECUTE' &&
        ['APPROVE', 'PROPOSE'].includes(av.roleAction) &&
        av.workflowTaskLabel === 'pending') ||
      (av.roleAction === 'EXECUTE' && av.workflowTaskLabel === 'pending')
    )
      requestType = av.workflowTaskActionDisplay
  })
  return requestType
}
