// highlight
import './utils/highlight'

// scroll bar
import 'simplebar/src/simplebar.css'

import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'

// redux
import { store, persistor } from './redux/store'
// contexts
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext'
// components
import CircularProgress from '@mui/material/CircularProgress'
import { AuthProvider } from './contexts/AWSCognitoContext'

//
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { GlobalInfoContextProvider } from 'contexts/GlobalStateContext'

import { WsContextProvider } from './contexts/WsContext'
import { SnackbarProvider } from 'notistack'

// ----------------------------------------------------------------------

ReactDOM.render(
  /* @ts-ignore */

  <HelmetProvider>
    {/* @ts-ignore */}

    <ReduxProvider store={store}>
      {/* @ts-ignore */}

      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <CollapseDrawerProvider>
          <SnackbarProvider maxSnack={1} preventDuplicate>
            <GlobalInfoContextProvider key="globalState">
              <BrowserRouter>
                <AuthProvider>
                  <WsContextProvider>
                    <App />
                  </WsContextProvider>
                </AuthProvider>
              </BrowserRouter>
            </GlobalInfoContextProvider>
          </SnackbarProvider>
        </CollapseDrawerProvider>
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
