import { createSlice } from '@reduxjs/toolkit'
import { Notification } from '../../@types/notification'

type InitalStateType = {
  notifications: Notification[]
  isLoading: boolean
  error: null
}
const initialState: InitalStateType = {
  notifications: [],
  isLoading: true,
  error: null
}

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
      state.notifications = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Notifications
    getAllNotificationsSuccess(state, action) {
      state.isLoading = false
      state.notifications = action.payload
      state.error = null
    },
    // Append Guarantee
    appendNotificationSuccess(state, action) {
      state.isLoading = false
      state.notifications = [action.payload, ...state.notifications]
      state.error = null
    },
    // CLEAR Notifications
    clearAllNotifications(state) {
      state.isLoading = false
      state.notifications = []
      state.error = null
    }
  }
})

export const {
  getAllNotificationsSuccess,
  hasError,
  startLoading,
  appendNotificationSuccess,
  clearAllNotifications
} = slice.actions

// Reducer
export default slice.reducer
