import { Grid, Typography } from '@mui/material'
import { INFO } from 'theme/palette'

const Subtitle = ({ text, fontWeight }) => (
  <Typography display="inline" fontSize="28" fontWeight={fontWeight} color={INFO.main}>
    {text}
  </Typography>
)

const getText = (type, rows) => {
  const isSingular = rows.length == 1
  const length = rows.length
  switch (type) {
    case 'my-task':
      return isSingular
        ? ['You have ', `${length}`, ' open Task.']
        : ['You have ', `${length}`, ' open Tasks.']
    case 'org-task':
      return isSingular
        ? ['There is ', `${length}`, ' open Organisation Task.']
        : ['There are ', `${length}`, ' open Organisation Tasks.']
    case 'notification':
      return isSingular
        ? ['You have ', `${length}`, ' new Notification.']
        : ['You have ', `${length}`, ' new Notifications.']
  }
}

type TaskCounterProps = { type: 'my-task' | 'org-task' | 'notification'; rows: any }
const TaskCounter = ({ type, rows }: TaskCounterProps) => {
  const textArray = getText(type, rows)

  return (
    <Grid item data-testid="task-counter">
      {textArray!.map((text, index) =>
        index === 1 ? (
          <Subtitle text={text} fontWeight={700} key={`${index}-${text}`} />
        ) : (
          <Subtitle text={text} fontWeight={400} />
        )
      )}
    </Grid>
  )
}

export default TaskCounter
