import { createSlice } from '@reduxjs/toolkit'

export interface IContractHistoryState {
  entries: any[]
}

const initialState: IContractHistoryState = {
  entries: []
}

export const contractHistorySlice = createSlice({
  name: 'contract history',
  initialState,
  reducers: {
    setHistory: (state, action) => {
      state.entries = action.payload.entries.reverse()
    }
  }
})

export const { setHistory } = contractHistorySlice.actions
export default contractHistorySlice.reducer
