// material
import { Typography, Button, Divider, Grid, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PATH_DASHBOARD } from 'routes/paths'
import { useState } from 'react'
import { Header } from 'pages/dashboard/headers/headers'
// ----------------------------------------------------------------------
const buttonStyles = {
  width: '160px',
  height: '60px',
  backgroundColor: '#1890FF'
}
const issuersMock = [
  'Anz',
  'Commonwealth Bank',
  'Westpac',
  'QBE',
  'NAB',
  'Suncorp',
  'Bank of Queensland',
  'Bank of Australia',
  'CGU'
]
export const IssuerSelection = () => {
  const [issuers] = useState(issuersMock)
  const navigate = useNavigate()
  //const { themeStretch } = useSettings()
  const handleBack = () => {
    navigate(PATH_DASHBOARD.general.guaranteeCreate.request)
  }

  const handleClick = () => {
    navigate(PATH_DASHBOARD.general.guaranteeCreate.create)
  }
  return (
    <>
      <Container>
        <Header handleBack={handleBack} />
        <Typography gutterBottom variant="h4">
          <br />
          Issuer Selection
        </Typography>
        <br />
        <Divider />
        <br />
        <Typography gutterBottom variant="subtitle2">
          <br />
          Please select your Issuer
        </Typography>
        <Grid container spacing={3} sx={{ mt: 5 }}>
          {issuers.map((issuer, index) => (
            <Grid item xs={12} md={12} lg={3} key={index + issuer}>
              <Button
                variant="outlined"
                sx={buttonStyles}
                onClick={handleClick}
                data-testid={index + issuer + '-btn'}
              >
                {issuer}
              </Button>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  )
}

export default IssuerSelection
