import { fCommasAndDecimals } from 'utils/formatNumber'

export const convertAmount = (amountSet: number) => {
  const isDecimal = /^\d+\.\d{0,2}$/
  return isDecimal.test(amountSet!.toString())
    ? {
        units: amountSet!.toString().split('.')[0],
        nanos: amountSet!.toString().split('.')[1],
        currencyCode: 'AUD'
      }
    : {
        units: amountSet!,
        nanos: 0,
        currencyCode: 'AUD'
      }
}

export const displayValue = (amount) =>
  amount?.nanos > 0 ? amount?.units + '.' + amount?.nanos : fCommasAndDecimals(amount?.units)
