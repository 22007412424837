const buttonStyles = {
  background: '#DFE3E8',
  boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
  borderRadius: '8px'
}

const overrideCurrencyStyle = {
  border: '1px solid rgba(145, 158, 171, 0.32)',
  borderRadius: '8px'
}

export { buttonStyles, overrideCurrencyStyle }
