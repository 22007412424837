import { useState } from 'react'
import { Outlet } from 'react-router-dom'
// material
import { styled, useTheme } from '@mui/material/styles'
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer'
//
import DashboardSidebar from './DashboardSidebar'
import { RootState, useSelector } from 'redux/store'
import { useOrgTasks } from 'hooks/useOrgTasks'
import { useMyTasks } from 'hooks/useMyTasks'

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64
const APP_BAR_DESKTOP = 92

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
})

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}))

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const theme = useTheme()
  const { notifications } = useSelector((state: RootState) => state?.notifications)
  const { myTasks } = useMyTasks()
  const { orgTasks } = useOrgTasks()

  const { collapseClick } = useCollapseDrawer()
  const [, setOpen] = useState(false)

  return (
    <RootStyle>
      {
        <DashboardSidebar
          isOpenSidebar={true}
          onCloseSidebar={() => setOpen(true)}
          noOfTasks={myTasks?.length}
          noOfNotifications={notifications?.length}
          noOfOrgTasks={orgTasks?.length}
        />
      }
      <MainStyle
        sx={{
          transition: theme.transitions.create('margin', {
            duration: theme.transitions.duration.complex
          }),
          ...(collapseClick && {
            ml: '102px'
          })
        }}
      >
        <Outlet />
      </MainStyle>
    </RootStyle>
  )
}
