// routes
import { PATH_DASHBOARD } from '../../routes/paths'
// components

import { Icon } from '@iconify/react'
import NotificationsIcon from 'layouts/common/NotificationsIcon'
// ----------------------------------------------------------------------

const sidebarConfig = (noOfTasks: number, noOfNotifications: number, noOfOrgTasks: number) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: '',
    items: [
      // {
      //   title: 'Dashboard',
      //   path: PATH_DASHBOARD.general.app,
      //   icon: ICONS.dashboard
      // },
      {
        title: 'Guarantees',
        path: PATH_DASHBOARD.general.app,
        icon: <Icon icon="foundation:thumbnails" width={24} height={24} color="#FFFF" />
      },
      {
        title: 'Notifications',
        path: PATH_DASHBOARD.general.notifications,
        icon: <NotificationsIcon />,
        noOfUnreadItems: noOfNotifications
      },
      {
        title: 'My Tasks',
        path: PATH_DASHBOARD.general.tasks,
        icon: <Icon icon="fluent:tasks-app-20-regular" width={24} height={24} color="#FFFF" />,
        noOfUnreadItems: noOfTasks
      },
      {
        title: 'Organisation Tasks',
        path: PATH_DASHBOARD.general.orgTasks,
        icon: (
          <Icon
            icon="streamline:money-bank-institution-money-saving-bank-payment-finance"
            width={24}
            height={24}
            color="#FFFF"
          />
        ),
        noOfUnreadItems: noOfOrgTasks
      }
    ]
  }
]

export const settingsConfig = [
  {
    subheader: '',
    items: [
      // {
      //   title: 'Account Settings',
      //   path: PATH_DASHBOARD.accountSettings.root,
      //   icon: <Icon icon="mdi:account-outline" width={24} height={24} color="#FFFF" />
      // },
      {
        title: 'Legals',
        path: PATH_DASHBOARD.accountSettings.root,
        icon: <Icon icon="mdi:folder-open" width={24} height={24} color="#FFFF" />
      }
      // {
      //   title: 'Help',
      //   path: PATH_DASHBOARD.accountSettings.root,
      //   icon: (
      //     <Icon icon="material-symbols:help-center-outline" width={24} height={24} color="#FFFF" />
      //   )
      // }
    ]
  }
]

export const termsAndConditions = [
  // {
  //   title: 'Terms of use',
  //   path: PATH_DASHBOARD.accountSettings.root
  // },
  // {
  //   title: 'Privacy Policy',
  //   path: PATH_DASHBOARD.accountSettings.root
  // }
]

export default sidebarConfig
