import React, { useState, ReactNode } from 'react'
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom'
// material
import { styled } from '@mui/material/styles'
import {
  Box,
  List,
  BoxProps,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  ListItemButton,
  ListItemButtonProps
} from '@mui/material'
import { ERROR } from 'theme/palette'

// ----------------------------------------------------------------------

const ListSubheaderStyle = styled((props) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary,
  backgroundColor: '#04297A'
}))

interface ListItemStyleProps extends ListItemButtonProps {
  component?: ReactNode
  to?: string
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: 'white',
  '&:before': {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: 'none',
    position: 'absolute',
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main
  }
}))

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
})

// ----------------------------------------------------------------------

type NavItemProps = {
  title: string
  path: string
  icon?: JSX.Element
  info?: JSX.Element
  children?: {
    title: string
    path: string
  }[]
  subitems?: any[]
  noOfUnreadItems?: number
}

const UnreadBadge = styled('div')<ListItemStyleProps>(() => ({
  backgroundColor: ERROR.main,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '6px',
  color: 'white',
  fontSize: '14px',
  width: '30px',
  height: '28px',
  padding: '1px',
}))

export function Footer({ items, ...other }) {
  return (
    <Box {...other} sx={{ display: 'flex', justifyContent: 'center', backgroundColor: '#04297A' }}>
      {items.map((list, index) => (
        <a
          style={{ color: '#919EAB', fontSize: '12px', padding: '20px', textDecoration: 'none' }}
          href={list?.path}
          key={`${list}-${index}`}
          id={`${list}-${index}`}
        >
          {list.title}
        </a>
      ))}
    </Box>
  )
}

function NavItem({
  item,
  isShow,
  notificationsToRead
}: {
  item: NavItemProps
  notificationsToRead?: number
  isShow?: boolean | undefined
}) {
  const { pathname } = useLocation()
  const { title, path, icon, info, children } = item
  const isActiveRoot = path ? !!matchPath({ path, end: false }, pathname) : false

  const [open, setOpen] = useState(isActiveRoot)

  const handleOpen = () => {
    setOpen(!open)
  }

  const activeRootStyle = {
    color: 'white',
    bgcolor: '#4F69A2',
    '&:before': { display: 'block' }
  }

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
        </ListItemStyle>
      </>
    )
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
        paddingLeft: '15px'
      }}
    >
      <ListItemIconStyle key={path}>{icon && icon}</ListItemIconStyle>
      {isShow && (
        <>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {!!notificationsToRead && (
            <UnreadBadge>
              {notificationsToRead > 99 ? (
                <>
                  99<small>+</small>
                </>
              ) : (
                notificationsToRead
              )}
            </UnreadBadge>
          )}
        </>
      )}
    </ListItemStyle>
  )
}

interface NavSectionProps extends BoxProps {
  isShow?: boolean | undefined
  notificationsToRead?: number
  navConfig: {
    subheader: string
    items: NavItemProps[]
  }[]
}

export default function NavSection({ navConfig, isShow = true, ...other }: NavSectionProps) {
  return (
    <Box {...other}>
      {navConfig.map((list, findex) => {
        const { subheader, items } = list
        return (
          <List key={`${subheader}-${findex}`} disablePadding>
            {isShow && <ListSubheaderStyle>{subheader}</ListSubheaderStyle>}
            {items.map((item: NavItemProps, index) => (
              <React.Fragment key={`${item.title}-${findex}-${index}`}>
                <NavItem
                  key={`${item.title}-${findex}-${index}`}
                  item={item}
                  isShow={isShow}
                  notificationsToRead={item.noOfUnreadItems}
                />
              </React.Fragment>
            ))}
          </List>
        )
      })}
    </Box>
  )
}
