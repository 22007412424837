export type DebugLevels = 'error' | 'info' | 'warn' | 'info'

export interface loggerInterface {
  debugMessage: (message: string) => void
  infoMessage: (message: string) => void
  warningMessage: (message: string) => void
  errorMessage: (message: string) => void
}

export class Logger implements loggerInterface {
  debugMessage = (message: string) => {
    this.writeMessages('debug', message)
  }

  infoMessage = (message: string) => {
    this.writeMessages('info', message)
  }

  warningMessage = (message: string) => {
    this.writeMessages('warn', message)
  }

  errorMessage = (message: string) => {
    this.writeMessages('error', message)
  }

  writeMessages = (level: string, message: string) => {
    console[level](message)
  }
}
