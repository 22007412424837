// material
import { Container } from '@mui/material'

// ----------------------------------------------------------------------

export default function Logo() {
  return (
    <Container sx={{ display: 'flex' }}>
      <svg
        width="101"
        height="32"
        viewBox="0 0 101 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M83.1618 9.2255C84.6445 9.2255 86.336 10.5105 86.336 12.3008C86.336 13.3003 86.0174 14.0801 85.5452 14.7831C85.2486 15.2334 85.018 15.7496 84.9191 16.134C84.8203 16.5623 84.5896 17.5838 85.8527 17.6058C86.6655 17.6277 88.6864 16.9138 90.3119 14.9588C91.564 13.3882 92.3109 11.3892 92.3109 9.21451C92.3109 4.12924 88.2251 -0.000488281 83.1728 -0.000488281C78.1204 -0.000488281 74.0237 4.12924 74.0237 9.21451C74.0237 11.3892 74.7705 13.3882 76.0226 14.9588C77.6482 16.9138 79.6581 17.6277 80.4819 17.6058C81.734 17.5838 81.5143 16.5623 81.4154 16.134C81.3276 15.7606 81.0969 15.2334 80.7894 14.7831C80.3171 14.0801 79.9986 13.3003 79.9986 12.3008C79.9876 10.5105 81.6681 9.2255 83.1618 9.2255ZM99.3292 18.5393C98.9558 18.2318 98.2968 17.7815 96.9458 17.5728C95.0787 17.2982 93.3982 17.7266 92.3988 18.133C91.0808 18.6602 89.4442 19.8464 88.3239 21.395C87.1377 23.0315 86.5227 25.5028 87.1158 28.0838C87.2805 28.8087 87.4672 29.2151 87.7308 29.7313C88.0823 30.4233 89.0049 31.3898 89.8177 31.5656C90.4547 31.6974 90.9599 31.3239 91.2016 31.0493C92.8381 29.1602 99.0327 21.8234 99.7027 20.8898C100.362 19.9342 99.9333 19.0446 99.3292 18.5393ZM73.9248 18.122C72.9144 17.7266 71.2449 17.2872 69.3777 17.5618C68.0268 17.7705 67.3678 18.2208 66.9943 18.5284C66.3903 19.0336 65.9509 19.9232 66.6209 20.8568C67.2909 21.7904 73.4855 29.1273 75.122 31.0164C75.3636 31.291 75.8689 31.6644 76.5169 31.5326C77.3296 31.3569 78.2522 30.3903 78.6037 29.6984C78.8783 29.1932 79.054 28.7868 79.2298 28.0509C79.8229 25.4698 79.1968 22.9986 78.0216 21.3621C76.8793 19.8354 75.2428 18.6492 73.9248 18.122Z"
          fill="#395BE5"
        />
        <path
          d="M63.0189 24.8324H55.3855L58.5157 20.7027H50.0366L47.0162 24.6786C45.8849 26.2602 45.7751 27.8528 45.7751 28.9621V29.5882C45.7751 30.3131 46.2254 30.7085 46.8844 30.7085H63.0189C63.6669 30.7085 64.1062 30.346 64.1062 29.6101V25.9307C64.1172 25.1948 63.6779 24.8324 63.0189 24.8324ZM19.5579 10.2575C19.4371 9.56558 18.9648 9.2251 18.3607 9.2251H16.208C13.9674 9.2251 11.9794 10.4113 10.8701 12.2126C10.8701 12.2126 8.80524 15.5076 6.49875 19.2089H21.1395L19.5579 10.2575ZM43.6883 10.3234C43.6883 9.58755 43.2489 9.2251 42.5899 9.2251H38.1966C37.5486 9.2251 37.1093 9.58755 37.1093 10.3234V18.6488L32.9466 11.1582C32.1118 9.77426 30.717 9.25805 29.0805 9.2251H25.8294C25.1814 9.2251 24.742 9.58755 24.742 10.3234V19.2199H30.2008L31.0355 20.7027H24.742V29.5991C24.742 30.335 25.1814 30.6975 25.8294 30.6975H30.2337C30.8927 30.6975 31.321 30.335 31.321 29.5991V21.2189L35.5277 28.7644C36.3514 30.1373 37.7573 30.6645 39.3828 30.6975H42.6009C43.2599 30.6975 43.6992 30.335 43.6992 29.5991V20.7027H38.2845L37.4497 19.2199H43.7102V10.3234H43.6883ZM21.4031 20.7027H14.1651C14.3518 21.7131 15.6808 29.2367 15.7687 29.676C15.8895 30.357 16.3178 30.6975 16.9329 30.6975H21.9083C22.3696 30.6975 22.5893 30.5547 22.809 30.2911C22.9518 30.1154 23.0177 29.8298 22.9518 29.4344L21.4031 20.7027ZM0.161381 29.3575C-0.0363159 29.698 -0.0363159 30.0495 0.0844955 30.2801C0.22728 30.5437 0.534809 30.7085 0.864311 30.7085H6.03746C6.68547 30.7085 7.26758 30.357 7.59708 29.8298L13.2974 20.7136H5.55419C2.90721 24.9642 0.227283 29.2587 0.161381 29.3575ZM47.0272 15.0792H54.2981L51.1569 19.2089H59.647L62.7443 15.1451C63.6999 13.882 63.9195 12.7617 63.9195 11.7622V10.3454C63.9195 9.62049 63.4802 9.2251 62.8102 9.2251H47.0272C46.3792 9.2251 45.9398 9.58755 45.9398 10.3234V14.0028C45.9508 14.7277 46.3792 15.0792 47.0272 15.0792Z"
          fill="#395BE5"
        />
      </svg>
    </Container>
  )
}
