import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { FormikErrors, FormikTouched } from 'formik'

type AmountAndCurrencyProps = {
  setCurrency: (value) => void
  amount: number | null
  setAmount: (value) => void
  touched?: FormikTouched<any>
  errors?: FormikErrors<any>
  values?: AmountAndCurrencyProps
}

export const AmountAndCurrency = ({
  setCurrency,
  amount,
  setAmount,
  touched,
  errors
}: AmountAndCurrencyProps) => {
  return (
    <>
      <Grid item md={4} lg={4}>
        <TextField
          type="number"
          label="Maximum Amount"
          sx={{ minWidth: '100%' }}
          placeholder="Enter maximum amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          InputLabelProps={{ shrink: true }}
          error={Boolean(touched?.amount && errors?.amount)}
          helperText={touched?.amount && errors?.amount}
          inputProps={{ maxLength: 15, inputMode: 'numeric' }}
        />
      </Grid>
      <Grid item md={1} lg={1} />
      <Grid item md={2} lg={2}>
        <FormControl>
          <InputLabel id="input-label">Currency</InputLabel>
          <Select
            labelId={`currency-label`}
            id={`currency-label`}
            label={`currency-name`}
            defaultValue="AUD"
            onChange={setCurrency}
          >
            <MenuItem value="AUD">AUD</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}
