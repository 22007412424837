import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// slices

import allGuaranteesReducer from './slices/all-guarantees'
import notificationsReducer from './slices/notifications'
import legalEntitiesReducer from './slices/legalEntities'
import termsReducer from './slices/terms'
import userReducer from './slices/user'
import pdfReducer from './slices/pdf'
import contractPdfReducer from './slices/contractPdf'

import createGuaranteeReducer from './slices/create-guarantee-request'
import setGuaranteeRequestPayloadReducer from './slices/create-payload'
import contractHistoryReducer from './slices/contract-history'
import purposeTypesReducer from './slices/purposeTypes'
import socketConnectReducer from './slices/socket-data'
import networkReducer from './slices/socket-data'

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-'
}

const gxPersistConfig = {
  key: 'guarantee',
  storage,
  keyPrefix: 'redux-'
}

const userPersistConfig = {
  key: 'user',
  storage,
  keyPrefix: 'redux-'
}

const createGxPersistConfig = {
  key: 'createGx',
  storage,
  keyPrefix: 'redux-'
}

const historyPersistConfig = {
  key: 'contractHistory',
  storage,
  keyPrefix: 'redux-'
}

const rootReducer = combineReducers({
  createGuarantee: createGuaranteeReducer,
  socketConnect: socketConnectReducer,
  createGuaranteePayload: persistReducer(createGxPersistConfig, setGuaranteeRequestPayloadReducer),
  allGuarantees: persistReducer(gxPersistConfig, allGuaranteesReducer),
  notifications: notificationsReducer,
  contractHistory: persistReducer(historyPersistConfig, contractHistoryReducer),
  legalEntities: legalEntitiesReducer,
  termsReducer: termsReducer,
  purposeTypes: purposeTypesReducer,
  network: networkReducer,
  userReducer: persistReducer(userPersistConfig, userReducer),
  pdfReducer: persistReducer(userPersistConfig, pdfReducer),
  contractPdfReducer: persistReducer(userPersistConfig, contractPdfReducer)
})

export { rootPersistConfig, rootReducer }
