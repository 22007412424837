import { createSlice } from '@reduxjs/toolkit'

type InitalStateType = {
  entities: any[]
  isLoading: boolean
  error: null
}
const initialState: InitalStateType = {
  entities: [],
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
      state.entities = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Legal Entities
    getAllLegalEntitiesSuccess(state, action) {
      state.isLoading = false
      state.entities = action.payload
      state.error = null
    },
    //Clear All Entities
    clearAllLegalEntites(state) {
      state.isLoading = false
      state.entities = []
      state.error = null
    }
  }
})

export const { getAllLegalEntitiesSuccess, hasError, startLoading, clearAllLegalEntites } =
  slice.actions

// Reducer
export default slice.reducer
