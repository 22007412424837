// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`
}

const ROOTS_AUTH = '/auth/login'
const ROOTS_DASHBOARD = '/dashboard'

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/admin/register'), // Protected route
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
}

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  page404: '/404',
  page500: '/500',
  components: '#'
}

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    tasks: path(ROOTS_DASHBOARD, '/tasks'),
    orgTasks: path(ROOTS_DASHBOARD, '/org-tasks'),
    onboarding: path(ROOTS_DASHBOARD, '/onboarding'),
    guarantee: path(ROOTS_DASHBOARD, '/guarantee'),
    notifications: path(ROOTS_DASHBOARD, '/notifications'),
    guaranteeCreate: {
      request: path(ROOTS_DASHBOARD, '/request'),
      issuer: path(ROOTS_DASHBOARD, '/request/select-issuer'),
      create: path(ROOTS_DASHBOARD, '/request/create-guarantee'),
      review: path(ROOTS_DASHBOARD, '/request/review-guarantee-request'),
      edit: path(ROOTS_DASHBOARD, '/edit'),
      editRequest: path(ROOTS_DASHBOARD, '/request/edit'),
      pending: path(ROOTS_DASHBOARD, '/pending')
    }
  },
  accountSettings: {
    root: path(ROOTS_DASHBOARD, '/account-settings')
  }
}

export const PATH_DOCS = 'https://lygon.io'
