import { styled } from '@mui/material/styles'

const SectionStyle = styled('div')(({ theme }) => ({
  width: '15%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'right',
  margin: theme.spacing(1, 0, 1, 1)
}))

export default SectionStyle
