import { ReactNode } from 'react'
import { Container, Alert, AlertTitle } from '@mui/material'
import { useAuth } from 'hooks/useAuth'
import LoadingScreen from 'components/LoadingScreen'

// ----------------------------------------------------------------------

type RoleBasedGuardProp = {
  accessibleRoles: string[]
  children: ReactNode | string
}

const useCurrentRole = () => {
  // Logic here to get current user role
  const { user, isInitialized, isAuthenticated } = useAuth()
  const role = user?.role
  return { role, isInitialized, isAuthenticated }
}

export default function RoleBasedGuard({ accessibleRoles, children }: RoleBasedGuardProp) {
  const { role, isInitialized, isAuthenticated } = useCurrentRole()
  if (!accessibleRoles.includes(role) && isInitialized) {
    return (
      <Container>
        <LoadingScreen />
      </Container>
    )
  } else if (!isAuthenticated) {
    return (
      <Container>
        <Alert severity="error">
          <AlertTitle>Permission Denied</AlertTitle>
          You do not have permission to access this page
        </Alert>
      </Container>
    )
  }

  return <>{children}</>
}
