import { Button } from '@mui/material'
import { Icon } from '@iconify/react'
import arrowCircleLeftFill from '@iconify/icons-eva/arrow-circle-left-fill'

export const Header = (props) => {
  return (
    <Button style={{ float: 'right', marginRight: '25px' }} onClick={props?.handleBack}>
      <Icon icon={arrowCircleLeftFill} color="#1890FF" height={36} style={{ paddingRight: 4 }} />{' '}
      Back
    </Button>
  )
}
