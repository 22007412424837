import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PurposeTypes } from '../../@types/purpose-types'

interface PurposeTypesState {
  purposeTypes: PurposeTypes[]
  isLoading: boolean
  error: null
}

const initialState: PurposeTypesState = {
  purposeTypes: [],
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: 'purpose-types',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Purpose Types
    getAllPurposeTypesSuccess(state, action: PayloadAction<PurposeTypes[]>) {
      state.isLoading = false
      state.purposeTypes = action.payload
      state.error = null
    }
  }
})

// Reducer
export const { getAllPurposeTypesSuccess, hasError, startLoading } = slice.actions
export default slice.reducer
