import { createSlice } from '@reduxjs/toolkit'

// ----------------------------------------------------------------------

export const initialState = {
  contractType: null,
  businessUnitActionContexts: [{}],
  isLoading: false,
  error: ''
}

/*
{
    "entries": [
        {
            "contractType": "TripartiteContract",
            "businessUnitActionContext": [
                {
                    "legalEntity": {
                        "legalEntityIdentifier": "LE00.LE01.2EYE.APPL.u+01.TEST",
                        "commonName": "Amazing Hot Dog Stand LE01 (APPL)",
                        "jurisdiction": "AU"
                    },
                    "businessUnit": {
                        "businessUnitIdentifier": "BU00.BU2I.LE01.APP1.2EYE.TEST",
                        "commonName": "APPLICANT BU"
                    },
                    "workflowRole": {
                        "roleIdentifier": "ROLE.APPL.OWNR.APP1.2EYE.TEST",
                        "commonName": "Boss Man",
                        "roleType": "OWNER"
                    },
                    "availableActions": [
                        {
                            "roleAction": "EXECUTE",
                            "workflowRole": {
                                "roleIdentifier": "ROLE.APPL.OWNR.APP1.2EYE.TEST",
                                "commonName": "Boss Man",
                                "roleType": "OWNER"
                            },
                            "legalEntity": {
                                "legalEntityIdentifier": "LE00.LE01.2EYE.APPL.u+01.TEST",
                                "commonName": "Amazing Hot Dog Stand LE01 (APPL)",
                                "jurisdiction": "AU"
                            },
                            "businessUnit": {
                                "businessUnitIdentifier": "BU00.BU2I.LE01.APP1.2EYE.TEST",
                                "commonName": "APPLICANT BU"
                            },
                            "bffTripartiteAction": {
                                "actionType": "CREATE",
                                "CREATE": {
                                    "applicants": [],
                                    "beneficiaries": [],
                                    "issuers": []
                                }
                            }
                        }
                    ]
                }
            ]
        }
    ]
}
*/
const slice = createSlice({
  name: 'createPayload',
  initialState,
  reducers: {
    // START LOADING
    startCreatePayloadLoading(state) {
      state.isLoading = true
    },

    // HAS ERROR
    hasCreatePayloadError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // Fetch Create Payload Guarantee
    setGuaranteeRequestPayload(state, action) {
      state.isLoading = false
      state.businessUnitActionContexts = [...action?.payload] || []
      state.contractType = action?.payload[0]?.contractType
    }
  }
})

// Reducer
export default slice.reducer

// ----------------------------------------------------------------------
export const { setGuaranteeRequestPayload, hasCreatePayloadError, startCreatePayloadLoading } =
  slice.actions

export function getCreatePayload(data: Response, status, dispatch) {
  dispatch(slice.actions.startCreatePayloadLoading())
  try {
    if (status.status === 'OK') {
      dispatch(slice.actions.setGuaranteeRequestPayload(data))
    } else {
      dispatch(slice.actions.hasCreatePayloadError('Error Occurred while fetching create Payload'))
    }
  } catch (error) {
    dispatch(slice.actions.hasCreatePayloadError(error))
  }
}
