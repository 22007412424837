import { Autocomplete, Box, Grid, TextField } from '@mui/material'
import { forwardRef, RefObject, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { BusinessIdAutoComplete } from './BusinessIdentity'
import { Request } from '../../../schemas/request'
import { Orgs } from './section'
import { useDispatch, RootState, useSelector } from 'redux/store'
import { GlobalInfoContext } from 'contexts/GlobalStateContext'
import { generateID } from 'utils/generateMessageID'
import { clearAllLegalEntites, getAllLegalEntitiesSuccess } from 'redux/slices/legalEntities'
import useSocketConnection from 'hooks/useSocketConnection'
import { clearAllTermsConditions } from 'redux/slices/terms'

type EntityProps = {
  label: string
  type: string
  touched?: any
  errors?: any
  getFieldProps?: any
  setValue: (value) => void
  optionalSelectedValue?: {
    commonName: string
    legalEntityIdentifier: string
    jurisdiction: string
  }
  initialOption?: Orgs
  handleClear: (value) => void
  initiatedBy: string
  initialValues: any
  resetTermsRef?: RefObject<any>
  resetIssuer?: RefObject<any>
  issuerRef?: RefObject<any>
  setContractFetch: (value: boolean) => void
  isTermsFetched?: boolean
  disabled?: boolean
  clearable?: boolean
}
export const EntityDetailsRow = forwardRef(
  (
    {
      label,
      type,
      touched,
      errors,
      getFieldProps,
      setValue,
      initialOption,
      handleClear,
      initiatedBy,
      initialValues,
      resetTermsRef,
      resetIssuer,
      issuerRef,
      setContractFetch,
      isTermsFetched,
      disabled,
      clearable = true
    }: EntityProps,
    ref: any
  ) => {
    const [optionSelected, setOption] = useState<Orgs>(
      initialOption || { label: '', businessId: '', country: '', displayId: '', type: '' }
    )
    const dispatch = useDispatch()
    const [orgs, setOrgsList] = useState<Orgs[]>([])
    const [labelEntered, setLabelEntered] = useState<string>('')
    const { onSocketSend } = useSocketConnection()
    const { entities } = useSelector((state: RootState) => state.legalEntities)
    const { issuers } = useSelector((state: RootState) => state.termsReducer)
    const [isSelected, setIsSelected] = useState(false)
    const { purpose, applicants, beneficiary } = useContext(GlobalInfoContext)

    const [open, setOpen] = useState(false)

    useEffect(() => {
      if (
        type !== 'issuer' &&
        applicants[0]?.businessId &&
        beneficiary[0]?.businessId &&
        purpose?.type &&
        !isTermsFetched &&
        !disabled
      ) {
        const requestTermsAndConditions: Request = {
          messageId: generateID(),
          messageType: 'QueryAvailableContractTerms',
          QueryAvailableContractTerms: {
            legalEntityIdentifier:
              initiatedBy === 'applicant' ? applicants[0]?.businessId : beneficiary[0]?.businessId,
            partyGroupRole: initiatedBy === 'applicant' ? 'APPLICANT' : 'BENE',
            purposeTypeIdentifier: purpose.id,
            applicants: [
              {
                legalEntityIdentifier: applicants[0]?.businessId,
                ConsortiumRole: 'SOLE_LE'
              }
            ],
            beneficiaries: [
              {
                legalEntityIdentifier: beneficiary[0]?.businessId,
                ConsortiumRole: 'SOLE_LE'
              }
            ]
          }
        }
        onSocketSend(requestTermsAndConditions)
        setContractFetch(true)
      }
    }, [applicants, beneficiary, purpose, isTermsFetched])
    useEffect(() => {
      const localOrgList: Orgs[] = []
      if (!disabled) {
        if (type !== 'issuer' && Object.keys(initialValues).length !== 0) {
          entities?.length > 0 &&
            initialValues?.forEach((legal) => {
              localOrgList.push({
                label: legal.businessUnitActionContext[0]?.legalEntity.commonName,
                businessId: legal.businessUnitActionContext[0]?.legalEntity.legalEntityIdentifier,
                displayId:
                  legal.businessUnitActionContext[0]?.legalEntity.legalEntityCodes[0]
                    .legalEntityCodeData,
                type: legal.businessUnitActionContext[0].legalEntity.legalEntityCodes[0]
                  ?.legalEntityCodeType
              })
            })
        }
      }
    }, [entities, initiatedBy])

    useEffect(() => {
      if (!disabled) {
        if (type !== 'issuer') {
          const requestLegalEntityLookup: Request = {
            messageId: generateID(),
            messageType: 'QueryLegalEntities',
            QueryLegalEntities: {
              legalNameExpression: labelEntered
            }
          }
          if (
            initialValues.length === 0 &&
            labelEntered !== null &&
            labelEntered !== '' &&
            !isSelected &&
            labelEntered.length > 2
          ) {
            onSocketSend(requestLegalEntityLookup)
          }
        }
      }
    }, [labelEntered, initiatedBy, isSelected])

    useEffect(() => {
      if (!disabled) {
        const orgList: Orgs[] = []
        if (type !== 'issuer') {
          initialValues?.length !== 0 &&
            initialValues?.forEach((legal) => {
              Object.keys(legal).length !== 0 &&
                orgList.push({
                  label: legal.businessUnitActionContext[0]?.legalEntity.commonName,
                  businessId: legal.businessUnitActionContext[0]?.legalEntity.legalEntityIdentifier,
                  displayId:
                    legal.businessUnitActionContext[0]?.legalEntity.legalEntityCodes[0]
                      .legalEntityCodeData,
                  type: legal.businessUnitActionContext[0].legalEntity.legalEntityCodes[0]
                    ?.legalEntityCodeType
                })
            })

          initialValues.length === 0 &&
            entities?.length > 0 &&
            entities?.forEach((legal) => {
              orgList.push({
                label: legal?.commonName,
                businessId: legal?.legalEntityIdentifier,
                displayId: legal?.legalEntityCodes[0].legalEntityCodeData,
                type: legal?.legalEntityCodes[0]?.legalEntityCodeType
              })
            })
          setOrgsList(orgList)
        } else if (type === 'issuer') {
          const issuerList: Orgs[] = []
          issuers.forEach((legal) => {
            Object.keys(legal).length !== 0 &&
              issuerList.push({
                label: legal?.issuerLegalEntity?.commonName,
                businessId: legal?.issuerLegalEntity?.legalEntityIdentifier,
                displayId: legal?.issuerLegalEntity?.legalEntityCodes[0]?.legalEntityCodeData
                  ? legal?.issuerLegalEntity?.legalEntityCodes[0]?.legalEntityCodeData
                  : 'NA',
                type: legal?.issuerLegalEntity?.legalEntityCodes[0].legalEntityCodeType
                  ? legal?.issuerLegalEntity?.legalEntityCodes[0]?.legalEntityCodeType
                  : 'NA'
              })
          })
          setOrgsList(issuerList)
        }
      }
    }, [initialValues, entities, issuers])

    useImperativeHandle(ref, () => ({
      resetOptions() {
        setOption({ label: '', businessId: '', country: '', displayId: '', type: '' })
        setOpen(false)
      }
    }))

    useImperativeHandle(issuerRef, () => ({
      resetIssuerOptions() {
        if (type === 'issuer') {
          setOption({ label: '', businessId: '', country: '', displayId: '', type: '' })
          setOpen(false)
        }
      }
    }))

    return (
      <>
        <Grid item md={4}>
          <Box>
            <Autocomplete
              disablePortal
              id={`${type}`}
              options={orgs}
              disabled={disabled}
              sx={{ minWidth: 200 }}
              disableClearable={!clearable}
              //getOptionLabel={(option) => option?.label}
              isOptionEqualToValue={(opts: Orgs, value: Orgs) => opts.label === value?.label}
              onInputChange={(event: any, value: string, reason: string) => {
                event?.preventDefault()
                event?.stopPropagation()
                if (reason === 'clear') {
                  if (type === 'applicant' || type === 'bene') {
                    dispatch(clearAllLegalEntites())
                    dispatch(clearAllTermsConditions())
                    resetIssuer?.current?.resetIssuerOptions()
                  }
                  resetTermsRef?.current.resetTerms()
                  setOption({ label: '', businessId: '', country: '', displayId: '', type: '' })
                  setLabelEntered('')
                  handleClear(type)
                  handleClear('issuer')
                  setIsSelected(false)
                } else {
                  value && setLabelEntered(value)
                }
              }}
              onChange={(event: any, option: Orgs, reason: string) => {
                if (reason === 'selectOption') {
                  event?.preventDefault()
                  event?.stopPropagation()
                  setIsSelected(true)
                  setOpen(false)
                  if (!!option) {
                    setOption(option)
                    setValue(option)
                  }
                }
              }}
              onHighlightChange={(event: any, _option: Orgs) => {
                event?.preventDefault()
                event?.stopPropagation()
                //setOpen(true)
              }}
              value={optionSelected}
              onBlur={() => {
                setOpen(false)
                dispatch(getAllLegalEntitiesSuccess([]))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  id={`${type}`}
                  placeholder="Search By Name..."
                  InputLabelProps={{ shrink: true }}
                  {...getFieldProps(`${type}`)}
                  error={Boolean(touched[`${type}`] && errors[`${type}`])}
                  helperText={touched[`${type}`] && errors[`${type}`]}
                />
              )}
            />
          </Box>
        </Grid>
        <Grid item md={1} lg={1} />
        <Grid item md={4}>
          <Box>
            <BusinessIdAutoComplete
              open={open}
              disabled={disabled}
              optionSelected={optionSelected}
              labelEntered={labelEntered}
              setLabelEntered={setLabelEntered}
              setOption={setOption}
              setValue={setValue}
              label={label.split(' ')[0]}
              type={type}
              initialValues={initialValues}
              isSelected={isSelected}
            />
          </Box>
        </Grid>
      </>
    )
  }
)
