import { useEffect, useState } from 'react'
import { RootState, useSelector } from 'redux/store'
import { Guarantee } from '../@types/guarantee'

// Helpers

export const getTaskDate = (guarantee: Guarantee) =>
  guarantee.TripartiteContractState?.eventTimestamp.substring(
    0,
    guarantee.TripartiteContractState?.eventTimestamp.length - 5
  )

export const getTaskId = (guarantee: Guarantee) =>
  guarantee.TripartiteContractState.contractIdentifier

const sortTasksByDate = (task1: Guarantee, task2: Guarantee) =>
  new Date(getTaskDate(task1)) > new Date(getTaskDate(task2)) ? -1 : 1

// ----------------------------------------------------------------------

export const useMyTasks = () => {
  const { allGuarantees, isLoading } = useSelector((state: RootState) => state?.allGuarantees)
  const [myTasks, setMyTasks] = useState<any[]>([])

  useEffect(() => {
    let filteredTasks: any[] = []
    allGuarantees?.forEach((gx: any) => {
      const { businessUnitActionContext } = gx
      businessUnitActionContext[0]?.availableActions.forEach((av) => {
        av.roleAction !== 'EXECUTE' &&
          ['APPROVE', 'PROPOSE'].includes(av.roleAction) &&
          av.workflowTaskLabel === 'pending' &&
          filteredTasks.push(gx)
        av.roleAction === 'EXECUTE' && av.workflowTaskLabel === 'pending' && filteredTasks.push(gx)
      })
    })
    filteredTasks.sort((a, b) => sortTasksByDate(a, b))
    setMyTasks(filteredTasks)
  }, [allGuarantees])

  return { myTasks, isLoading }
}
