import { useState, useContext, useEffect } from 'react'
// // material
import { Typography, Box, Grid } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'
import { useLocation } from 'react-router-dom'
import CreateGuaranteeHeader from 'pages/dashboard/headers/CreateGuaranteeHeader'
import { GlobalInfoContext } from 'contexts/GlobalStateContext'
import { PurposeTypeSelection } from './sections/autocomplete/DropDowns'
import CommercialLease from './sections/forms/CommercialLease'
import PaymentPerformanceGuarantee from './sections/forms/Payment&Performance'
import { useDispatch } from 'redux/store'
import { clearAllLegalEntites } from 'redux/slices/legalEntities'
import { clearAllTermsConditions } from 'redux/slices/terms'
import { RootState, useSelector } from 'redux/store'
import Page from 'components/Page'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    paddingBottom: '2rem',
    justifyContent: 'space-between',
    textAlign: 'right',
    fontFamily: 'EH Normal',
    fontWeight: 700,
    fontSize: 20
  }
})

export const GuaranteeCreation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [, setPurposeType] = useState('')
  const location = useLocation()

  const { setReqInfo, purpose } = useContext(GlobalInfoContext)
  const { purposeTypes } = useSelector((state: RootState) => state?.purposeTypes)

  const setInfo = (value: { label: string; id: string }) => {
    const enumValue =
      value?.label === 'Payment Guarantee'
        ? 'PAYMENTGX'
        : value?.label === 'Performance Guarantee'
        ? 'PERFORMANCEGX'
        : value?.label === ''
        ? ''
        : 'COMMERCIALLEASE'
    setReqInfo({ purpose: { type: enumValue, id: value?.id } })
    setPurposeType(enumValue)
    dispatch(clearAllLegalEntites())
    dispatch(clearAllTermsConditions())
  }

  useEffect(() => {
    dispatch(clearAllLegalEntites())
    dispatch(clearAllTermsConditions())
  }, [location])

  return (
    <Page sx={{ margin: '0 24px 0 18px' }}>
      <CreateGuaranteeHeader label="Create New Guarantee Request" />
      <Box sx={{ flexGrow: 1, ...classes }}>
        <Typography>What is the purpose of the guarantee?</Typography>
        <Grid container item spacing={2} sx={{ marginTop: '0.5rem', marginBottom: '1rem' }}>
          <PurposeTypeSelection setPurposeType={setInfo} purposeTypes={purposeTypes} />
        </Grid>
      </Box>
      <Grid>
        {purpose?.type !== 'Commercial Lease' && purpose?.type && <PaymentPerformanceGuarantee />}
        {purpose?.type === 'Commercial Lease' && <CommercialLease />}
      </Grid>
    </Page>
  )
}

export default GuaranteeCreation
