import { styled } from '@mui/material'
import { GREY } from 'theme/palette'

const cellBorder = `1px solid ${GREY[400]}`

export const TableContainer = styled('div')({
  borderRadius: '4px',
  border: cellBorder,
  borderBottom: 'none',
  width: '100%',
  overflow: 'hidden',
})

export const Table = styled('table')({
  width: '100%',
  fontSize: '14px',
  borderCollapse: 'collapse',
  'th, td': {
    padding: '14px'
  },
  tr: {
    borderBottom: cellBorder
  },
  th: {
    backgroundColor: GREY[200],
    fontWeight: 700,
    height: '36px',
    textAlign: 'left'
  },
  td: {
    backgroundColor: GREY[100],
    '&:hover': {
      cursor: 'pointer'
    }
  }
})
