import axios from 'axios'
import { Logger } from './logger'

// axios.defaults.baseURL = 'https://beta.lygon.com'
axios.defaults.baseURL = 'http://localhost:3000'

const customAxios = axios.create()
const logger = new Logger()

customAxios.interceptors.request.use(
  (request) => {
    logger.infoMessage('Request initiated in the logger to the URL' + request.baseURL)
    return request
  },
  (error) => {
    logger.errorMessage('Error in the logger' + error)
    return Promise.reject(error)
  }
)

customAxios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
)
export default customAxios
