import { createSlice } from '@reduxjs/toolkit'

type InitalStateType = {
  issuers: any[]
  isLoading: boolean
  error: null
}
const initialState: InitalStateType = {
  issuers: [],
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: 'issuers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
      state.issuers = []
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET T&C
    getAllTermsConditionsSuccess(state, action) {
      state.isLoading = false
      state.issuers = action.payload.availableTermsList

      state.error = null
    },
    //Clear All Terms and Conditions
    clearAllTermsConditions(state) {
      state.isLoading = false
      state.issuers = []
      state.error = null
    }
  }
})

export const { getAllTermsConditionsSuccess, hasError, startLoading, clearAllTermsConditions } =
  slice.actions

// Reducer
export default slice.reducer
