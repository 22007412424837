import { format, getTime, formatDistanceToNow } from 'date-fns'

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy')
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p')
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date))
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy p')
}

export function fDateSffixWithoutUTC(date: string) {
  const dateWithoutUTC = date.substring(0, date.length - 5)

  return format(new Date(dateWithoutUTC), 'dd/MM/yyyy p')
}

export function fDateForCreate(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy')
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  })
}

export function fDateStringForEval(pDate: String) {
  if (pDate === 'No Expiry Date') return '2200/01/01'
  if (pDate.includes('-')) return pDate.split('-').join('/')
  if (pDate.includes('/'))
    return pDate
      .split('/')
      .map((val) => (Number(val) <= 9 ? val.padStart(2, '0') : val))
      .reverse()
      .join('/')
  return pDate
}

export function fDateWithTimeZone(date: string) {
  const dateWithoutUTC = new Date(date.substring(0, date.length - 5))

  const formattedDate = new Intl.DateTimeFormat('en-AU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h12',
    timeZoneName: 'short'
  }).format(dateWithoutUTC)

  // convert 'am' or 'pm' to 'AM' or 'PM', remove comma
  return formattedDate.replace(/(\b\w{2}\b)|/g, (match) => match.toUpperCase()).replace(',', '')
}
