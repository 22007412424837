import { useEffect, useState } from 'react'
import { RootState, useSelector } from 'redux/store'
import { Guarantee } from '../@types/guarantee'

// Helpers

export const getRequestType = (pRow: Guarantee) => {
  const { subStateDisplay, stateDisplay } = pRow.TripartiteContractState
  return subStateDisplay ? `(${subStateDisplay})` : stateDisplay
}

// ----------------------------------------------------------------------

export const useOrgTasks = () => {
  const { allGuarantees, isLoading } = useSelector((state: RootState) => state?.allGuarantees)
  const [orgTasks, setOrgTasks] = useState<Guarantee[]>([])

  useEffect(() => {
    const filteredTasks: Guarantee[] = allGuarantees?.filter((gx: Guarantee) => {
      const { organizationTask } = gx
      return organizationTask
    })
    setOrgTasks(filteredTasks)
  }, [allGuarantees])

  return { orgTasks, isLoading }
}
