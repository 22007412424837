import { Link as RouterLink } from 'react-router-dom'
import { styled } from '@mui/material/styles'
// material
import { Box, Button, Typography, Container } from '@mui/material'
// components
import Page from '../components/Page'
import NoNotification from 'assets/illustration_no_notification'

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(15)
  }
}))

// ----------------------------------------------------------------------

export default function NoNotifications(props) {
  return (
    <RootStyle title={props?.title}>
      <Container>
        <Box sx={{ maxWidth: 300, maxHeight: 300, margin: 'auto', textAlign: 'center' }}>
          <NoNotification />
          <Typography sx={{ color: 'text.secondary' }}>There are no new {props?.type}.</Typography>
          <br />
          <Button to="/dashboard/app" size="large" variant="contained" component={RouterLink}>
            Go to Home
          </Button>
        </Box>
      </Container>
    </RootStyle>
  )
}
