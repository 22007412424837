import { createContext, ReactNode, useCallback, useEffect, useReducer, useState } from 'react'
import {
  CognitoUser,
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUserSession,
  CognitoUserAttribute
} from 'amazon-cognito-identity-js'
// utils
import axios from '../utils/axiosCustom'
import Cookies from 'js-cookie'
// routes
import { PATH_AUTH } from '../routes/paths'
// @types
import { ActionMap, AuthState, AuthUser, AWSCognitoContextType } from '../@types/authentication'
//
import { cognitoConfig } from '../config'
import { useNavigate } from 'react-router'
// ----------------------------------------------------------------------

export const UserPool = new CognitoUserPool({
  UserPoolId: cognitoConfig.userPoolId || '',
  ClientId: cognitoConfig.clientId || ''
})

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
}

enum Types {
  auth = 'AUTHENTICATE',
  logout = 'LOGOUT'
}

type AwsAuthPayload = {
  [Types.auth]: {
    isAuthenticated: boolean
    user: AuthUser
  }
  [Types.logout]: undefined
}

type AwsActions = ActionMap<AwsAuthPayload>[keyof ActionMap<AwsAuthPayload>]

const reducer = (state: AuthState, action: AwsActions) => {
  if (action.type === 'AUTHENTICATE') {
    const { isAuthenticated, user } = action.payload
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    }
  }
  if (action.type === 'LOGOUT') {
    return {
      ...state,
      isAuthenticated: false,
      user: null
    }
  }
  return state
}

const AuthContext = createContext<AWSCognitoContextType | null>(null)
AuthContext.displayName = 'AWSCognito context'

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState)
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [session, setSession] = useState<CognitoUserSession | null>(null)
  const navigate = useNavigate()

  const getUserAttributes = useCallback(
    (currentUser: CognitoUser): Record<string, any> =>
      new Promise((resolve, reject) => {
        currentUser.getUserAttributes((err, attributes) => {
          if (err) {
            reject(err)
          } else {
            const results: Record<string, any> = {}

            attributes?.forEach((attribute) => {
              results[attribute.Name] = attribute.Value
            })
            resolve(results)
          }
        })
      }),
    []
  )

  useEffect(() => {
    const timer = setInterval(() => {
      checkTokenExpiration()
    }, 60 * 1000)
    return () => clearInterval(timer)
  }, [user, session])

  const checkTokenExpiration = () => {
    const accessTokenExpiration = session?.getAccessToken().getExpiration()
    const currentTime = Math.floor(new Date().getTime() / 1000) // Convert to seconds
    if (accessTokenExpiration && accessTokenExpiration > currentTime) {
      console.info('Session valid')
    } else if (accessTokenExpiration && accessTokenExpiration < currentTime) {
      console.warn('Session inValid redirecting to the login page')
      logout()
      navigate('/auth/login')
    }
  }

  const getSession = useCallback(
    () =>
      new Promise((resolve, reject) => {
        const user = UserPool.getCurrentUser()
        setUser(user)
        if (user) {
          user.getSession(async (err: Error | null, session: CognitoUserSession | null) => {
            if (err) {
              reject(err)
            } else {
              setSession(session)
              const attributes = getUserAttributes(user)
              const token = session?.getIdToken()?.getJwtToken()
              const expiry = new Date(new Date().getTime() + 60 * 60 * 1000)

              token &&
                Cookies.set('token', token, {
                  expires: expiry,
                  secure: true,
                  domain: cognitoConfig.domain,
                  sameSite: 'strict'
                })

              axios.defaults.headers.common.Authorization = token
              dispatch({
                type: Types.auth,
                payload: { isAuthenticated: true, user: attributes }
              })
              resolve({
                user,
                session,
                headers: { Authorization: token }
              })
            }
          })
        } else {
          dispatch({
            type: Types.auth,
            payload: {
              isAuthenticated: false,
              user: null
            }
          })
        }
      }),
    [getUserAttributes]
  )

  const initial = useCallback(async () => {
    try {
      await getSession()
    } catch {
      dispatch({
        type: Types.auth,
        payload: {
          isAuthenticated: false,
          user: null
        }
      })
    }
  }, [getSession])

  useEffect(() => {
    initial()
      .then(() => {
        //Nothing to do
      })
      .catch((error) => console.log('Error while initialisation', error))
  }, [initial])

  const login = useCallback(
    (email, password) =>
      new Promise((resolve, reject) => {
        const user = new CognitoUser({
          Username: email,
          Pool: UserPool
        })

        const authDetails = new AuthenticationDetails({
          Username: email,
          Password: password
        })
        window.sessionStorage.setItem('email', email)
        const expiry = new Date(new Date().getTime() + 60 * 60 * 1000)

        user.authenticateUser(authDetails, {
          onSuccess: async (data) => {
            Cookies.set('token', data.getIdToken().getJwtToken(), {
              expires: expiry,
              domain: cognitoConfig.domain,
              secure: true,
              sameSite: 'strict'
            })
            getSession()
              .then(() => resolve(data))
              .catch((error) => console.log('Error getting session', error))
          },
          onFailure: (err) => {
            reject(err)
          },
          newPasswordRequired: () => {
            // Handle this on login page for update password.
            resolve({ message: 'newPasswordRequired' })
          }
        })
      }),
    [getSession]
  )

  // same thing here
  const logout = () => {
    const user = UserPool.getCurrentUser()
    if (user) {
      user.signOut()
      dispatch({ type: Types.logout })
    }
  }

  const register = (email: string, password: string, firstName: string, lastName: string) =>
    new Promise((resolve, reject) => {
      UserPool.signUp(
        email,
        password,
        [
          new CognitoUserAttribute({ Name: 'email', Value: email }),
          new CognitoUserAttribute({ Name: 'name', Value: `${firstName} ${lastName}` })
        ],
        [],
        async (err) => {
          if (err) {
            reject(err)
            return
          }
          resolve(undefined)
          window.location.href = PATH_AUTH.login
        }
      )
    })

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'cognito',
        user: {
          displayName: state?.user?.name,
          role: window.sessionStorage?.getItem('email')?.split('@')[0],
          ...state.user
        },
        login,
        register,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthContext, AuthProvider }
