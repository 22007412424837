import { generateID } from 'utils/generateMessageID'
import Guarantee from '../../@types/guarantee'
import { Request } from '../../@types/request'
import { displayValue } from 'components/request-action/sections/forms/utils'

export const constructRows = (data: Guarantee[]) => {
  const rowsToBeFilled: any = []
  data?.forEach((gx, zindex) => {
    const { TripartiteContractState: tcs, reference } = gx
    const eventTimeStamp = tcs?.eventTimestamp
    // Adding for now to supress the duplicate key error
    rowsToBeFilled.push({
      key: tcs?.contractIdentifier + zindex,
      id: tcs?.contractIdentifier,
      reference,
      status: tcs?.stateDisplay,
      applicant: tcs?.applicants[0]?.commonName,
      beneficiary: tcs?.beneficiaries[0]?.commonName,
      issuer: tcs?.issuers[0]?.commonName,
      amount: displayValue(tcs?.amount),
      requestedDate: new Date(eventTimeStamp?.substring(0, eventTimeStamp?.length - 5)),
      currency: tcs?.amount?.currencyCode,
      expiryDate: tcs?.expires.date.DD
        ? `${tcs?.expires.date.DD}/${tcs?.expires.date.MM}/${tcs?.expires.date.YYYY}`
        : 'No Expiry Date'
    })
  })
  return rowsToBeFilled
}

export const constructRequestRows = (data: Request[], setRows) => {
  const rowsToBeFilled: any = []
  data?.forEach((res: any, zindex) => {
    const amount = res?.TripartiteContractState?.payload?.amount?.outstanding
      ? res?.TripartiteContractState?.payload?.amount?.outstanding +
        ' ' +
        res?.TripartiteContractState?.payload?.amount?.currency
      : '0 AUD'
    rowsToBeFilled.push({
      id: res?.TripartiteContractState?.id,
      key: res?.TripartiteContractState?.id + zindex,
      type: res?.TripartiteContractState?.type,
      status: res?.TripartiteContractState?.status,
      createdBy: res?.TripartiteContractState?.enactedBy[0],
      amount: amount,
      requestedDate: new Date(
        res?.TripartiteContractState?.eventTimestamp.substring(
          0 - res?.TripartiteContractState?.eventTimestamp.length - 5
        )
      ),
      expiryDate: new Date(res?.TripartiteContractState?.expiresAt)
    })
  })
  setRows(rowsToBeFilled)
}

export type Stepper = {
  label: string
  completed: boolean
}

export function downloadAsPDF(id: string, roleIdentifier: string, title: string) {
  let base64String = window.sessionStorage.getItem('pdf')
  console.log('Documentid::>>>', id)
  console.log('roleID:>>>', roleIdentifier)
  if (base64String && base64String.startsWith('JVB')) {
    base64String = 'data:application/pdf;base64,' + base64String
    downloadFileObject(base64String, title)
  } else if (base64String && base64String.startsWith('data:application/pdf;base64')) {
    downloadFileObject(base64String, title)
  } else {
    alert('Not a valid Base64 PDF string. Please check')
  }
}

export function base64ToPdf(fileName: string) {
  let base64String = window.sessionStorage.getItem('contractPdf') || ''

  // Decode base64 string to binary
  const binaryString = atob(base64String)

  // Create Uint8Array from binary string
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  // Create Blob from Uint8Array
  const blob = new Blob([bytes.buffer], { type: 'application/pdf' })

  // Create URL for the Blob
  const url = URL.createObjectURL(blob)

  // Create an anchor element
  const anchor = document.createElement('a')
  anchor.href = url
  anchor.download = fileName

  // Programmatically click the anchor element to trigger the download
  anchor.click()

  // Clean up the URL object after download
  URL.revokeObjectURL(url)
}

const downloadFileObject = (base64String, title) => {
  const linkSource = base64String
  const downloadLink = document.createElement('a')
  const fileName = `${title}.pdf`
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

export const sendDocumentRequest = (
  roleIdentifier: string,
  documentIdentifier: string,
  onSocketSend
): Promise<boolean> => {
  return new Promise((res) => {
    const documentReq = {
      messageId: generateID(),
      messageType: 'QueryDocument',
      QueryDocument: {
        roleIdentifier: roleIdentifier,
        documentIdentifier: documentIdentifier
      }
    }
    onSocketSend(documentReq).then(() => {
      return res(true)
    })
  })
}
export function getLabelFromPathItem(pItem) {
  switch (pItem) {
    case 'A':
      return 'Applicant Approval'
    case 'B':
      return 'Beneficiary Approval'
    case 'I':
      return 'Issuer Approval'
    default:
      return ''
  }
}

export const findIssuerDetailsByID = (issuers, idToSearch) => {
  let issuer
  issuers.forEach((issu) => {
    if (issu?.issuerLegalEntity?.legalEntityIdentifier === idToSearch) {
      issuer = [issu.issuerLegalEntity]
    }
  })
  return issuer
}

export const findTermsNameFromTermsID = (issuers, termsIdentifier) => {
  let termFetched
  issuers.forEach((issu) => {
    issu.terms.forEach((term) => {
      if (term.termsIdentifier === termsIdentifier) {
        termFetched = term
      }
    })
  })
  return termFetched
}

export const findTermsDetailsByID = (terms, idToSearch) => {
  let termFetched
  terms &&
    terms.forEach((term) => {
      if (term.termsIdentifier === idToSearch) {
        termFetched = term
      }
    })
  return termFetched
}

export const fetchIssuerDetailsFromHistory = (entry, issus) => {
  const legalIdentity =
    entry?.WorkflowAction?.bffTripartiteAction?.YEP_WITH_DATA?.issuers[0]?.legalEntityIdentifier
  const issuerfetched = findIssuerDetailsByID(issus, legalIdentity)

  return {
    issuer: issuerfetched,
    termsIdentifier: findTermsDetailsByID(
      issus[0]?.terms,
      entry?.WorkflowAction?.bffTripartiteAction?.YEP_WITH_DATA?.termsIdentifier
        ? entry?.WorkflowAction?.bffTripartiteAction?.YEP_WITH_DATA?.termsIdentifier
        : entry?.WorkflowAction?.bffTripartiteAction?.CREATE?.contractTermsIdentifier
    )
  }
}

export const fetchTermsID = (history) => {
  const actionType = history.WorkflowAction?.bffTripartiteAction?.actionType
  const termsIDFromWorkflow =
    Object.keys(history).includes('WorkflowAction') &&
    Object.keys(history?.WorkflowAction) &&
    Object.keys(history?.WorkflowAction?.bffTripartiteAction).includes(actionType) &&
    Object.keys(history?.WorkflowAction?.bffTripartiteAction[actionType]).includes(
      'contractTermsIdentifier'
    )
      ? history.WorkflowAction?.bffTripartiteAction[actionType]?.contractTermsIdentifier
      : ''
  const termsToReturn = termsIDFromWorkflow
  return termsToReturn
}

export const demandValues = (history, gxRetrieved: Guarantee) => {
  const demandTypes = ['DEMAND_PARTIAL_PAYMENT', 'DEMAND_FULL_PAYMENT']
  let demandData
  const keyItems = history && history?.entries?.length > 0 && Object.keys(history?.entries[0])
  if (keyItems && keyItems?.includes('ContractDisplayItem') && keyItems.includes('ContractEvent')) {
    if (
      history?.entries[0]?.ContractDisplayItem?.contractConsensusResult === 'PENDING' &&
      history?.entries[0]?.ContractEvent?.TripartiteContractState.demandAmount
    ) {
      demandData = {
        state: history?.entries[0]?.ContractEvent?.TripartiteContractState.transitionEvent,
        demandAmount: history?.entries[0]?.ContractEvent?.TripartiteContractState.demandAmount,
        fullAmount: gxRetrieved?.TripartiteContractState?.amount
      }
    }
  } else if (
    keyItems &&
    keyItems?.includes('WorkflowDisplayItem') &&
    keyItems.includes('WorkflowAction')
  ) {
    const workflow = Object.keys(history.entries[0]?.WorkflowAction?.bffTripartiteAction)
    if (
      workflow.includes(demandTypes[0]) &&
      history?.entries[0]?.WorkflowDisplayItem?.workflowConsensusResult === 'PENDING'
    ) {
      demandData = {
        state: history.entries[0]?.WorkflowAction?.bffTripartiteAction.transitionEvent,
        demandAmount:
          history.entries[0]?.WorkflowAction?.bffTripartiteAction['DEMAND_PARTIAL_PAYMENT']
            ?.demandAmount,
        fullAmount: gxRetrieved?.TripartiteContractState?.amount
      }
    } else if (
      workflow.includes(demandTypes[1]) &&
      history?.entries[0]?.WorkflowDisplayItem?.workflowConsensusResult === 'PENDING'
    ) {
      demandData = {
        state: history.entries[0]?.WorkflowAction?.bffTripartiteAction?.actionType,
        demandAmount: gxRetrieved?.TripartiteContractState?.amount,
        fullAmount: gxRetrieved?.TripartiteContractState?.amount
      }
    } else if (gxRetrieved?.TripartiteContractState?.demandAmount) {
      demandData = {
        state: gxRetrieved?.TripartiteContractState?.transitionEvent,
        demandAmount: gxRetrieved?.TripartiteContractState?.demandAmount,
        fullAmount: gxRetrieved?.TripartiteContractState?.amount
      }
    } else {
      demandData = null
    }
  }
  return demandData
}

export const checkWhetherYepExists = (guarantee?: Guarantee) => {
  let whetherYEP = false
  guarantee?.businessUnitActionContext[0]?.availableActions.forEach((ava) => {
    if (ava.bffTripartiteAction.actionType === 'YEP_WITH_DATA') {
      whetherYEP = true
    }
  })
  return whetherYEP
}

export const convertGxDetailsToContext = (gx?: Guarantee) => {
  const obj = {
    reference: gx?.reference,
    applicants: [
      {
        label: gx?.TripartiteContractState.applicants[0].commonName,
        businessId: gx?.TripartiteContractState.applicants[0].legalEntityIdentifier,
        countryId: gx?.TripartiteContractState.applicants[0].jurisdiction,
        displayId:
          gx?.TripartiteContractState.applicants[0].legalEntityCodes[0].legalEntityCodeData,
        type: gx?.TripartiteContractState.applicants[0].legalEntityCodes[0].legalEntityCodeType
      }
    ],
    beneficiary: [
      {
        label: gx?.TripartiteContractState.beneficiaries[0].commonName,
        businessId: gx?.TripartiteContractState.beneficiaries[0].legalEntityIdentifier,
        countryId: gx?.TripartiteContractState.beneficiaries[0].jurisdiction,
        displayId:
          gx?.TripartiteContractState.beneficiaries[0].legalEntityCodes[0].legalEntityCodeData,
        type: gx?.TripartiteContractState.beneficiaries[0].legalEntityCodes[0].legalEntityCodeType
      }
    ],
    issuers: [
      {
        label: gx?.TripartiteContractState.issuers[0].commonName,
        businessId: gx?.TripartiteContractState.issuers[0].legalEntityIdentifier,
        countryId: gx?.TripartiteContractState.issuers[0].jurisdiction,
        displayId: gx?.TripartiteContractState.issuers[0].legalEntityCodes[0].legalEntityCodeData,
        type: gx?.TripartiteContractState.issuers[0].legalEntityCodes[0].legalEntityCodeType
      }
    ],
    amount: gx?.TripartiteContractState?.amount,
    purpose: {
      type: gx?.TripartiteContractState.purpose,
      purposeDescription: JSON.parse(gx?.TripartiteContractState.purposeData)?.purposeDescription,
      contract: { ...JSON.parse(gx?.TripartiteContractState.purposeData)?.contract },
      property: { ...JSON.parse(gx?.TripartiteContractState.purposeData)?.property },
      expiryDate: `${gx?.TripartiteContractState.expires.date.YYYY}/${gx?.TripartiteContractState.expires.date.MM}/${gx?.TripartiteContractState.expires.date.DD}`,
      isOpenEnded: gx?.TripartiteContractState?.expires?.date?.never
    }
  }
  return obj
}

export const purposeDataDetermination = (history, type) => {
  let purposeData
  const propertyToCheck =
    type === 'purpose' ? 'newPurposeData' : type === 'expiry' ? 'newExpires' : 'newAmount'
  const keyItems = history && history?.entries?.length > 0 && Object.keys(history?.entries[0])
  if (
    keyItems &&
    keyItems?.includes('ContractDisplayItem') &&
    keyItems?.includes('ContractEvent')
  ) {
    if (history?.entries[0]?.ContractEvent?.TripartiteContractState[propertyToCheck]) {
      if (type === 'purpose') {
        purposeData = JSON.parse(
          history?.entries[0]?.ContractEvent?.TripartiteContractState[propertyToCheck]
        )
      } else {
        purposeData = history?.entries[0]?.ContractEvent?.TripartiteContractState[propertyToCheck]
      }
    }
  } else if (
    keyItems &&
    keyItems?.includes('WorkflowDisplayItem') &&
    keyItems.includes('WorkflowAction')
  ) {
    const workflow = Object.keys(history.entries[0]?.WorkflowAction?.bffTripartiteAction)
    if (workflow.includes('AMEND')) {
      if (type === 'purpose') {
        purposeData = JSON.parse(
          history.entries[0]?.WorkflowAction?.bffTripartiteAction?.AMEND[propertyToCheck]
        )
      } else {
        purposeData =
          history.entries[0]?.WorkflowAction?.bffTripartiteAction?.AMEND[propertyToCheck]
      }
    } else {
      purposeData = null
    }
  }
  return purposeData
}

export const convertEnumValuesToUserReadableForm = (pType: string): string => {
  let userReadablePType =
    pType === 'PAYMENTGX'
      ? 'Payment Guarantee'
      : pType === 'PERFORMANCEGX'
      ? 'Performance Guarantee'
      : 'Commercial Lease'
  return userReadablePType
}

export const fetchReference = (history, gxRetrieved: Guarantee) => {
  const actionType = history.WorkflowAction?.bffTripartiteAction?.actionType
  const refFromWorkflow =
    Object.keys(history).includes('WorkflowAction') &&
    Object.keys(history?.WorkflowAction) &&
    Object.keys(history?.WorkflowAction?.bffTripartiteAction).includes(actionType) &&
    Object.keys(history?.WorkflowAction?.bffTripartiteAction[actionType]).includes('reference')
      ? history.WorkflowAction?.bffTripartiteAction[actionType]?.reference
      : undefined

  const refToReturn =
    gxRetrieved?.reference || gxRetrieved?.reference === ''
      ? gxRetrieved?.reference
      : refFromWorkflow
  return refToReturn
}

export const checkIRNUpdate = (history) => {
  const refFromWorkflow =
    history &&
    Object.keys(history).includes('WorkflowAction') &&
    Object.keys(history?.WorkflowAction) &&
    Object.keys(history?.WorkflowAction?.bffTripartiteAction).includes('UPDATE_REFERENCE') &&
    Object.keys(history?.WorkflowAction?.bffTripartiteAction['UPDATE_REFERENCE']).includes(
      'reference'
    ) &&
    Object.keys(history?.WorkflowEvent).includes('workflowState') &&
    history.WorkflowEvent.workflowState === 'PROPOSED'
      ? history.WorkflowAction?.bffTripartiteAction['UPDATE_REFERENCE']?.reference
      : undefined

  return refFromWorkflow
}
