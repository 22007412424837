import { useContext } from 'react'
import { WsContext } from '../contexts/WsContext'

// ----------------------------------------------------------------------

export const useWsContext = () => {
  const context = useContext(WsContext)

  if (!context) throw new Error('Socket context must be use inside Socket Provider')

  return context
}
