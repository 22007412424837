import { createSlice } from '@reduxjs/toolkit'
import { User } from '../../@types/user'

type InitalStateType = {
  user: User
  isLoading: boolean
  error: null
}
const initialState: InitalStateType = {
  user: {
    nameLabelDisplay: '',
    orgLabelDisplay: ''
  },
  isLoading: false,
  error: null
}

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true
      state.user = {
        nameLabelDisplay: '',
        orgLabelDisplay: ''
      }
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false
      state.error = action.payload
    },

    // GET Notifications
    getUserDetials(state, action) {
      state.isLoading = false
      state.user = action.payload
      state.error = null
    }
  }
})

export const { getUserDetials, hasError, startLoading } = slice.actions

// Reducer
export default slice.reducer
