import { createSlice } from '@reduxjs/toolkit'

type InitalStateType = {
  contractPdf: string
  isPdfLoading: boolean
  error: null
}
const initialState: InitalStateType = {
  contractPdf: '',
  isPdfLoading: false,
  error: null
}

const slice = createSlice({
  name: 'contractPdf',
  initialState,
  reducers: {
    // START LOADING
    startLoadingContractPDF(state) {
      state.isPdfLoading = true
      state.contractPdf = ''
    },

    // HAS ERROR
    hasError(state, action) {
      state.isPdfLoading = false
      state.error = action.payload
    },

    // GET
    getContractPdf(state, action) {
      state.isPdfLoading = false
      state.contractPdf = action.payload
      state.error = null
    }
  }
})

export const { getContractPdf, hasError, startLoadingContractPDF } = slice.actions

// Reducer
export default slice.reducer
