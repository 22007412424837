import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isConnected: true
}

const networkSlice = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setConnectivity: (state, action) => {
      state.isConnected = action.payload
    }
  }
})

export const { setConnectivity } = networkSlice.actions

export default networkSlice.reducer
