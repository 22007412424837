import { Suspense, lazy } from 'react'
import { Navigate, useRoutes, useLocation } from 'react-router-dom'
// layouts
import DashboardLayout from '../layouts/dashboard'
// guards
import GuestGuard from '../guards/GuestGuard'
// components
import LoadingScreen from '../components/LoadingScreen'
import { Notifications, MyTasks, OrgTasks } from 'components/dashboard-menu'

import IssuerSelection from 'components/request-action/IssuerSelection'
import GuaranteeCreation from 'components/request-action/GuaranteeCreation'
import RoleBasedGuard from '../guards/RoleBasedGuard'
import { useAuth } from 'hooks/useAuth'

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation()
  const isDashboard = pathname.includes('/dashboard/app')
  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  )
}

export default function Router() {
  const { user } = useAuth()
  let children
  if (user?.role !== 'admin') {
    children = [
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            )
          },
          { path: 'login-unprotected', element: <Login /> }
        ]
      },

      // Dashboard Routes
      {
        path: 'dashboard',
        element: <DashboardLayout />,
        children: [
          { element: <Navigate to="/dashboard/app" key="navigate" replace /> },
          { path: 'app', element: <DashboardPage key="dashboard-page" replace /> },
          {
            path: 'tasks',
            element: <MyTasks key="tasks-dashboard" />
          },
          {
            path: 'org-tasks',
            element: <OrgTasks key="orgtasks-dashboard" />
          },
          {
            path: 'notifications',
            element: <Notifications key="notifications" />
          },
          { path: 'guarantee/:id', element: <Guarantee key="guarantee-detail" /> },
          { path: 'edit/:id', element: <EditGuarantee key="edit-guarantee" /> },
          {
            path: 'request',
            children: [
              { element: <Navigate to="/" replace /> },
              { path: 'select-issuer', element: <IssuerSelection key="issuer-selection" /> },
              {
                path: 'create-guarantee',
                element: <GuaranteeCreation key="create-guarantee" />
              },
              {
                path: 'review-guarantee-request',
                element: <RequestReviewPage key="review-guarantee-request" />
              }
            ]
          }
        ]
      },
      { path: '*', element: <Navigate to="/auth/login" replace /> }
    ]
  } else if (user?.role === 'admin') {
    children = [
      {
        path: 'auth',
        children: [
          {
            path: 'login',
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            )
          },
          { path: 'login-unprotected', element: <Login /> }
        ]
      },

      // Dashboard Routes
      {
        path: 'dashboard',
        element: (
          <RoleBasedGuard accessibleRoles={['admin']}>
            <DashboardLayout />
          </RoleBasedGuard>
        ),
        children: [
          { element: <Navigate to="/dashboard/app" key="navigate" replace /> },
          { path: 'app', element: <AdminDashboardPage key="dashboard-page" replace /> },
          {
            path: 'notifications',
            element: <Notifications key="notifications" />
          }
        ]
      },
      { path: '*', element: <Navigate to="/auth/login" replace /> }
    ]
  }
  return useRoutes(children)
}
// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')))
// Notifications
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashboardPage')))
const AdminDashboardPage = Loadable(
  lazy(() => import('../pages/dashboard/admin/AdminDashboardPage'))
)

const Guarantee = Loadable(lazy(() => import('../pages/dashboard/GuaranteeViewPage')))
const RequestReviewPage = Loadable(lazy(() => import('../pages/dashboard/RequestReviewPage')))

const EditGuarantee = Loadable(lazy(() => import('../pages/dashboard/GuaranteeEditPage')))
