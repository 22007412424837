// material
import { BoxProps, Container } from '@mui/material'

// ----------------------------------------------------------------------

export default function Logo({ sx }: BoxProps) {
  const color = sx && sx['color'] ? sx['color'] : '#101828'
  return (
    <Container sx={{ display: 'flex' }}>
      <svg
        width="106"
        height="40"
        viewBox="0 0 106 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18.6369 31.2849V27.486L4.46927 27.486L4.46927 0L0 0L0 31.2849L18.6369 31.2849Z"
          fill={color}
        />
        <path
          d="M26.1724 27.1732L19.7366 7.59777L15.2673 7.59777L22.4182 29.1397H25.502L21.9712 39.3296H26.4852L37.2562 7.59777H32.7869L26.1724 27.1732Z"
          fill={color}
        />
        <path
          d="M47.7282 40C53.3148 40 58.4545 36.9162 58.4545 28.9609V7.59777L54.2086 7.59777V10.4581C52.555 8.26816 50.231 6.92738 47.2366 6.92738C41.4265 6.92738 36.8232 11.9777 36.8232 18.9944C36.8232 25.8771 41.203 31.0615 47.1025 31.0615C50.231 31.0615 52.5103 29.5866 53.9852 27.6201L53.9852 29.8994C53.9852 34.2346 51.5718 36.3352 47.5047 36.3352C45.1807 36.3352 42.9014 35.6648 42.0522 33.0279H37.717C39.1919 38.8827 44.3315 40 47.7282 40ZM47.8623 27.3967C43.8846 27.3967 41.1584 23.6872 41.1584 18.9944C41.1584 14.3017 44.0634 10.5922 47.8623 10.5922C50.2757 10.5922 52.8679 12.1564 53.9852 14.9721V22.838C52.9125 25.5196 50.5885 27.3967 47.8623 27.3967Z"
          fill={color}
        />
        <path
          d="M71.939 31.9553C78.6429 31.9553 83.291 26.7263 83.291 19.4413C83.291 12.1564 78.6429 6.92738 71.939 6.92738C65.2351 6.92738 60.5871 12.1564 60.5871 19.4413C60.5871 26.7263 65.2351 31.9553 71.939 31.9553ZM71.939 28.2905C67.3804 28.2905 64.8329 24.2682 64.8329 19.4413C64.8329 14.6145 67.3804 10.5922 71.939 10.5922C76.4977 10.5922 79.0452 14.3911 79.0452 19.4413C79.0452 24.4916 76.4977 28.2905 71.939 28.2905Z"
          fill={color}
        />
        <path
          d="M97.4527 6.92738C94.5029 6.92738 91.732 8.49162 90.0337 10.7263V7.59777H86.0113V31.2849H90.2571V14.5251C91.5979 12.3799 93.8772 10.5922 96.38 10.5922C99.151 10.5922 101.207 12.2011 101.207 16.4469V31.2849L105.453 31.2849V16C105.453 10.1453 102.548 6.92738 97.4527 6.92738Z"
          fill={color}
        />
      </svg>
    </Container>
  )
}
