// material
import { Typography, CardContent } from '@mui/material'

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function AppWelcome() {
  return (
    <CardContent
      sx={{
        color: 'grey.800'
      }}
    >
      <Typography gutterBottom variant="subtitle2">
        <br />
        Welcome back, {window.sessionStorage.getItem('email')}!
      </Typography>
    </CardContent>
  )
}
