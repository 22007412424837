import { Grid, Stack, Typography } from '@mui/material'

const CreateGuaranteeHeader = ({ label }) => {
  return (
    <Grid item xs={12}>
      <Stack direction="row" spacing={1} sx={{ paddingBottom: '2rem' }}>
        <Typography variant="h5">{label}</Typography>
      </Stack>
    </Grid>
  )
}

export default CreateGuaranteeHeader
