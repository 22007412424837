import SimpleBarReact, { Props } from 'simplebar-react'
// material
import { styled } from '@mui/material/styles'
import { BoxProps } from '@mui/material'

// ----------------------------------------------------------------------

const RootStyle = styled('div')(() => ({
  flexGrow: 1,
  height: '100%'
}))

const SimpleBarStyle = styled(SimpleBarReact)(({ }) => ({
  maxHeight: '100%'
}))

// ----------------------------------------------------------------------

export default function Scrollbar({ children, sx }: BoxProps & Props) {
  return (
    <RootStyle>
      <SimpleBarStyle key="simple-bar-style" timeout={500} clickOnTrack={false} sx={sx}>
        {children}
      </SimpleBarStyle>
    </RootStyle>
  )
}
