import { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useAuth } from './useAuth'
import useSocketConnection from './useSocketConnection'
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, idleTime = 1 }) => {
  const idleTimeout = 1000 * idleTime
  const [isIdle, setIdle] = useState(false)
  const { logout } = useAuth()
  const { onSocketClosed } = useSocketConnection()
  const handleIdle = () => {
    setIdle(true)
    onSocketClosed()
    logout()
  }
  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500
  })
  return {
    isIdle,
    setIdle,
    idleTimer
  }
}
export default useIdleTimeout
