import { useEffect, useRef } from 'react'

import { RootState, useSelector } from '../redux/store'
import schemas from '../schemas'
import Cookies from 'js-cookie'

import { eventCentral } from 'redux/action-creators/event-central'
import addMessageChunks from 'utils/messageSequence'

import { generateID } from 'utils/generateMessageID'
import { useWsContext } from './useWsContext'

export interface useSocketConnectPropsInterface {
  serviceURl: string
}

export interface useSocketConnectInterface {
  isConnected: boolean
  onSocketOpen: () => void
  connectSocket: () => void
  onSocketSend: () => Promise<unknown>
  onSocketClosed: () => Promise<boolean>
  reconnectSocket: () => void
  socket: WebSocket
}

export default function useSocketConnection() {
  const isConnected = useSelector((state: RootState) => state.socketConnect.isConnected)

  const socket = useRef<WebSocket | null>()
  let decodedValues = {}
  const ws = useWsContext()

  useEffect(() => {
    if (ws.webSocket) {
      socket.current = ws.webSocket
      socket.current.onopen = () => onSocketOpen()
      socket.current.onclose = () => onSocketClosed()
      socket.current.onmessage = ({ data }) => onSocketMessage(data)
    }
  }, [ws.webSocket])

  const onSocketClosed = () => {
    const fetchedValue = Cookies.get('token')
    if (ws.webSocket?.readyState === ws.webSocket?.OPEN) {
      onSocketSend({
        messageId: generateID(),
        messageType: 'DeAuth',
        DeAuth: {
          jwt: fetchedValue
        }
      }).then(() => {
        Cookies.remove('token')
        setTimeout(() => {
          console.log('Close: *--------Closing Connection from Hook-------*')
          ws.webSocket?.close()
        }, 500)
      })
    }
  }
  const onSocketMessage = (data: typeof schemas.Response) => {
    // TODO: data should come as an object
    const dataObj = JSON.parse(data.toString())

    const messageType = dataObj.messageType
    switch (messageType) {
      case 'MessageSegment32KB':
        decodedValues[dataObj.messageIdReference] = addMessageChunks(
          dataObj,
          decodedValues[dataObj.messageIdReference]
        )
        if (
          dataObj[`${messageType}`]?.part === dataObj[`${messageType}`]?.parts &&
          !!decodedValues
        ) {
          eventCentral(decodedValues[dataObj.messageIdReference])
        }

        break

      default:
        eventCentral(data.toString())

        break
    }
  }

  const onSocketSend = async (data: typeof schemas.Response) =>
    new Promise((resolve, reject) => {
      try {
        waitForSocketConnection(socket, function () {
          socket.current?.send(JSON.stringify(data))
        })

        return resolve(true)
      } catch (error) {
        console.log('error', error)
        return reject(false)
      }
    })

  const onSocketOpen = () => {
    // console.log('value inside onSOcketOpen', value)
    const fetchedValue = Cookies.get('token')
    //if (value) {
    if (socket.current?.readyState !== WebSocket.CONNECTING) {
      const intitateAuth: typeof schemas.Request = {
        messageId: generateID(),
        messageType: 'Auth',
        Auth: {
          jwt: fetchedValue
        }
      }
      socket.current?.send(JSON.stringify(intitateAuth))
    }
    // dispatch(socketOpenAndConnect())

    //}
  }

  //This function is used from the useSocketData hook
  function reconnectSocket() {
    console.log('-----Reconnecting websocket------')
    onSocketOpen()
  }

  // Make the function wait until the connection is made...
  function waitForSocketConnection(socket, callback) {
    setTimeout(function () {
      if (socket.current?.readyState === 1) {
        if (callback != null) {
          callback()
        }
      } else {
        waitForSocketConnection(socket, callback)
      }
    }, 500) // wait 5 milisecond for the connection...
  }

  const onSocketError = (error) => console.log('websocket error: ', error)

  return {
    isConnected,
    onSocketOpen,
    onSocketClosed,
    onSocketSend,
    socket,
    reconnectSocket,
    onSocketError
  }
}
